import { useMantineTheme } from "@mantine/core";
import { ErrorMessage } from "formik";
import type { DetailedHTMLProps, InputHTMLAttributes } from "react";

import { FileInputFieldProps } from "../types/FileInputFieldProps";

import Asterik from "./ContactUs/Asterisk";

const FileInputField = ({
    name,
    error,
    touch,
    handleChange,
    labelName,
    desc,
    fieldrequired,
    ...restProps
}: FileInputFieldProps &
    Partial<
        DetailedHTMLProps<
            InputHTMLAttributes<HTMLInputElement>,
            HTMLInputElement
        >
    >) => {
    const theme = useMantineTheme();
    return (
        <div>
            <label
                style={{
                    color:
                        theme.colorScheme === "dark"
                            ? theme.colors.gray[3]
                            : theme.colors.secondary[4],
                    fontWeight: 500,
                }}
                htmlFor={name}
            >
                {labelName}
            </label>
            {fieldrequired && <Asterik />}
            <div
                style={{
                    padding: 8,
                    border: `1px solid ${theme.colors.gray[3]}`,
                    borderRadius: 6,
                }}
                className={
                    error && touch
                        ? "file-attachment is-invalid"
                        : "file-attachment"
                }
            >
                <input
                    accept=".pdf,.doc,.docx"
                    {...restProps}
                    type="file"
                    name={name}
                    id={name}
                    className="fileUpload"
                    onChange={handleChange}
                    style={{ padding: "5px 10px 5px 10px" }}
                />
            </div>
            <p
                style={{
                    fontSize: 14,
                    fontWeight: 400,
                    marginTop: 6,
                    color: "#94A3B8",
                }}
            >
                {desc}
            </p>
            {name && (
                <ErrorMessage
                    name={name}
                    component="span"
                    className="invalid-feedback"
                />
            )}
        </div>
    );
};

export default FileInputField;
