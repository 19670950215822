import { Container, useMantineTheme } from "@mantine/core";

const CookiesPolicy = () => {
    const theme = useMantineTheme();
    return (
        <Container size={1520} mb={120} mt={40}>
            <h1
                style={{
                    color: theme.colors.brand[0],
                    fontWeight: 800,
                    marginBottom: 40,
                }}
            >
                Cookies Policy
            </h1>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Introduction
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                This Cookie Policy is designed to help you understand how we use
                cookies and similar tracking technologies on our website. By
                using our website, you consent to the use of cookies as
                described in this policy.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                What Are Cookies?
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Cookies are small text files that are placed on your computer or
                mobile device when you visit a website. They are widely used to
                make websites work efficiently and to provide information to
                website owners.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                How We Use Cookies
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We use cookies for the following purposes:
                <br />
                <br />
                <span style={{ fontWeight: 500, color: "black" }}>
                    Essential Cookies:
                </span>{" "}
                These cookies are necessary for the proper functioning of our
                website. They enable you to navigate our site and use its
                features.
                <br /> <br />
                <span style={{ fontWeight: 500, color: "black" }}>
                    Analytical/Performance Cookies:
                </span>{" "}
                These cookies allow us to recognize and count the number of
                visitors to our website and see how visitors move around our
                site. This helps us improve the way our website works.
                <br /> <br />
                <span style={{ fontWeight: 500, color: "black" }}>
                    Functionality Cookies:
                </span>{" "}
                These cookies are used to recognize you when you return to our
                website. They enable us to personalize content and remember your
                preferences.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Types of Cookies We Use
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Session Cookies: These cookies are temporary and are deleted
                from your device when you close your web browser.
                <br />
                Persistent Cookies: These cookies remain on your device for a
                specified period or until you delete them.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Third-Party Cookies
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may also use third-party cookies to gather statistics about
                our website. These cookies are set and controlled by a third
                party.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Managing Cookies
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                You can control and manage cookies in various ways. Please note
                that removing or blocking cookies may affect your user
                experience on our website.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Changes to This Policy
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may update this Cookie Policy from time to time. Any changes
                will be posted on this page with an updated date.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Contact Information
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                If you have any questions or concerns regarding this Cookie
                Policy, please contact us at info@cagtu.com
            </p>
        </Container>
    );
};
export default CookiesPolicy;
