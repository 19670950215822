import {
    Box,
    Container,
    Flex,
    Grid,
    Group,
    useMantineTheme,
} from "@mantine/core";
import { useAboutUsPageStyles } from "../styles/pages/AboutUsStyles";
import {
    IconArrowNarrowRight,
    IconBrandAsana,
    IconEyeCheck,
    IconFilterSearch,
    IconHourglassLow,
    IconMapPinFilled,
    IconRobot,
    IconTargetArrow,
} from "@tabler/icons-react";
import {
    IconBulb,
    IconHome,
    IconInfoCircle,
    IconRocket,
    IconUsers,
} from "tabler-icons";
import FocusInfo from "../components/OurFocus";
import { Link } from "react-router-dom";
import { useState } from "react";

const AboutUs = () => {
    const [hovered0, setisHovered0] = useState(false);
    const [hovered1, setHovered1] = useState(false);
    const [hovered2, setHovered2] = useState(false);
    const { classes } = useAboutUsPageStyles();
    const theme = useMantineTheme();
    return (
        <>
            <Flex justify={"center"} align={"center"} direction={"column"}>
                <Flex
                    className={classes.description}
                    mt={80}
                    direction={"column"}
                    justify={"center"}
                    align={"center"}
                >
                    <h1>
                        Cagtu:{" "}
                        <span style={{ color: theme.colors.primary[0] }}>
                            Transforming
                        </span>{" "}
                        The
                        <br /> World By Creating Digital Footprints
                    </h1>
                    <p style={{ textAlign: "center" }}>
                        A team of young professionals working together to bridge
                        the ideas with Technology to build amazing products and
                        services .
                    </p>
                </Flex>

                <section>
                    <Container size={1520} mt={120} mb={120}>
                        <Grid align="center" p={"1rem"} justify="center">
                            <Grid.Col md={5}>
                                {" "}
                                <img
                                    src="/assets/Products/Product-discussion.png"
                                    alt=""
                                    className={classes.productImage}
                                />
                            </Grid.Col>
                            <Grid.Col md={7}>
                                <div className={classes.productInfo}>
                                    <h2>Empower. Innovate. Transform.</h2>
                                    <p>
                                        We embody the essence of Empower,
                                        Innovate, Transform. We empower
                                        businesses with cutting-edge solutions,
                                        innovate to redefine industry norms, and
                                        transform challenges into opportunities.
                                        Our commitment to these principles
                                        drives us to shape a future where
                                        technology isn't just a tool, but a
                                        catalyst for remarkable change. Join us
                                        on this transformative journey and
                                        discover the limitless potential of what
                                        technology, in the right hands, can
                                        achieve.
                                    </p>
                                    <p style={{ marginTop: 16 }}>
                                        Our strength emanates from the
                                        collective brilliance of our team
                                    </p>
                                    <Link to={"/contactus"}>
                                        {" "}
                                        <div className={classes.connect}>
                                            <h4>LET’S CONNECT</h4>
                                            <IconArrowNarrowRight
                                                size={24}
                                                color={theme.colors.primary[0]}
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </Grid.Col>
                        </Grid>
                    </Container>
                </section>

                <Container mb={143} size={"xl"}>
                    <Flex mb={40} direction={"column"}>
                        <h2
                            style={{
                                textAlign: "center",
                                marginBottom: 16,
                                color: theme.colors.brand[0],
                                fontSize: 32,
                                fontWeight: 700,
                            }}
                        >
                            Our Focus
                        </h2>
                        <p>
                            We are on a mission to transform the way modern
                            challenges are tackled.
                        </p>
                    </Flex>
                    <Grid
                        gutter={15}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 110,
                        }}
                        p={"1rem"}
                    >
                        <Grid.Col md={4}>
                            <img
                                src="/assets/Products/focus.png"
                                alt=""
                                loading="lazy"
                                className={classes.gridimage}
                            />
                        </Grid.Col>
                        <Grid.Col md={6}>
                            <FocusInfo
                                icon={IconRobot}
                                title="Revolutionizing through Technology"
                                desc="We firmly believe that every problem has a technological solution waiting to be discovered, and our team of experts is dedicated to turning this belief into reality."
                            />
                            <FocusInfo
                                icon={IconHourglassLow}
                                title="Shaping Tomorrow, Today"
                                desc="We envision a future where technology doesn't just solve problems—it empowers industries, individuals, and communities. "
                            />
                            <FocusInfo
                                icon={IconBulb}
                                title="Research, Innovation, Persistence"
                                desc="At Cagtu, our core values are more than just words—they're the driving force behind everything we do. "
                            />
                        </Grid.Col>
                    </Grid>
                </Container>
            </Flex>
            <Box mb={116}>
                <Container fluid size={1920}>
                    <div className={classes.aim}>
                        <Flex
                            align={"center"}
                            justify={"center"}
                            gap={30}
                            direction={{ base: "column", sm: "row" }}
                            mb={100}
                        >
                            <Box className={classes.vision}>
                                <h2
                                    style={{
                                        textAlign: "center",
                                        color: "#17365C",
                                        fontSize: 32,
                                        marginBottom: 24,
                                    }}
                                >
                                    Mission
                                </h2>
                                <p
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    Through continuous research and innovative
                                    concepts, our plan is to provide solutions
                                    for modern day problems with the use of
                                    technology.
                                </p>
                            </Box>
                            <Box className={classes.vision}>
                                <h2
                                    style={{
                                        textAlign: "center",
                                        color: "#17365C",
                                        marginBottom: 24,
                                        fontSize: 32,
                                    }}
                                >
                                    Vision
                                </h2>
                                <p style={{ textAlign: "center" }}>
                                    We aspire to become the leading company that
                                    empowers technology and digitalization to
                                    provide exciting solutions in various
                                    sectors.
                                </p>
                            </Box>
                            <Box className={classes.vision}>
                                <h2
                                    style={{
                                        textAlign: "center",
                                        color: "#17365C",
                                        marginBottom: 24,
                                        fontSize: 32,
                                    }}
                                >
                                    Values
                                </h2>
                                <p style={{ textAlign: "center" }}>
                                    Every solution we provide or the innovation
                                    we make is made with utmost research and
                                    hardwork. We know nothing is impossible, but
                                    not everything is easy either.
                                </p>
                            </Box>
                        </Flex>
                        <Group spacing={80} className={classes.focusgroup}>
                            <Flex gap={24} align={"center"}>
                                <IconFilterSearch
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Research Oriented
                                </p>
                            </Flex>
                            <Flex gap={24} align={"center"}>
                                <IconRocket
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Tenacity
                                </p>
                            </Flex>
                            <Flex gap={24} align={"center"}>
                                <IconBulb
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Innovate
                                </p>
                            </Flex>
                            <Flex gap={24} align={"center"}>
                                <IconBrandAsana
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Diverse
                                </p>
                            </Flex>
                            <Flex gap={24} align={"center"}>
                                <IconTargetArrow
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Purpose Driven
                                </p>
                            </Flex>
                            <Flex gap={24} align={"center"}>
                                <IconEyeCheck
                                    size={24}
                                    color={theme.colors.primary[0]}
                                />
                                <p style={{ fontSize: 18, fontWeight: 400 }}>
                                    Visionary
                                </p>
                            </Flex>
                        </Group>
                    </div>
                </Container>
            </Box>

            <Flex
                justify={"center"}
                align={"center"}
                direction={"column"}
                mb={200}
            >
                <h2
                    style={{
                        fontSize: 32,
                        fontWeight: 700,
                        color: theme.colors.brand[0],
                        marginBottom: 32,
                    }}
                >
                    Meet Our Team
                </h2>

                <Container size={1920}>
                    <Grid>
                        <Grid.Col
                            md={4}
                            className="centered-col"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                onMouseEnter={() => {
                                    setisHovered0(true);
                                }}
                                onMouseLeave={() => {
                                    setisHovered0(false);
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        margin: "auto",
                                        overflow: "visible",
                                        transition: "transform 0.3s ease",
                                        "&:hover": {
                                            transform: "scale(1.05)",
                                            ".overlay": {
                                                opacity: 1,
                                                transition: "opacity 0.3s ease",
                                            },
                                            ".content-details": {
                                                bottom: 10,
                                                right: 10,
                                                opacity: 1,
                                                transition:
                                                    "bottom 0.3s ease, right 0.3s ease, opacity 0.3s ease", // Add smooth transitions
                                            },
                                        },
                                    }}
                                    className="content"
                                >
                                    <img
                                        src="/assets/teams/sikha.png"
                                        alt=""
                                        loading="lazy"
                                        style={{
                                            borderRadius: hovered0
                                                ? "4px 4px 0px 0px"
                                                : "4px",
                                            height: "100%",
                                            width: "100%",
                                            maxHeight: 478,
                                            maxWidth: 358,
                                        }}
                                    />

                                    {hovered0 === true ? (
                                        <Box
                                            mb={0}
                                            className="icon-block"
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                left: 0,
                                                height: "125%",
                                                top: 0,
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                zIndex: -1,
                                                bottom: 0,
                                                width: "100%",
                                                borderRadius: "0px 0px 4px 4px",
                                                background: "white",
                                                boxShadow:
                                                    "0px 0px 20px 10px rgba(33, 29, 79, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
                                            }}
                                        >
                                            <Flex
                                                justify={"center"}
                                                align={"center"}
                                                direction={"column"}
                                            >
                                                <h2
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                        color: "#17365C",
                                                    }}
                                                >
                                                    Sikha Parajuli
                                                </h2>
                                                <h2
                                                    style={{
                                                        color: "#17365C",
                                                        fontWeight: 600,
                                                        marginTop: 8,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    Business Consultant
                                                </h2>
                                                <p
                                                    style={{
                                                        color: "#475569",
                                                        display: "flex",
                                                        gap: 4,
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    <IconMapPinFilled
                                                        size={16}
                                                        color="#475569"
                                                    />
                                                    Sydney, Australia
                                                </p>
                                            </Flex>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                        </Grid.Col>
                        <Grid.Col
                            md={4}
                            className="centered-col"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {" "}
                            <Box
                                onMouseEnter={() => {
                                    setHovered1(true);
                                }}
                                onMouseLeave={() => {
                                    setHovered1(false);
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        margin: "auto",
                                        overflow: "visible",
                                        transition: "transform 0.3s ease",
                                        "&:hover": {
                                            transform: "scale(1.05)",
                                            ".overlay": {
                                                opacity: 1,
                                                transition: "opacity 0.3s ease",
                                            },
                                            ".content-details": {
                                                bottom: 10,
                                                right: 10,
                                                opacity: 1,
                                                transition:
                                                    "bottom 0.3s ease, right 0.3s ease, opacity 0.3s ease",
                                            },
                                        },
                                    }}
                                    className="content"
                                >
                                    <img
                                        src="/assets/teams/suman.png"
                                        alt=""
                                        loading="lazy"
                                        style={{
                                            borderRadius: hovered1
                                                ? "4px 4px 0px 0px"
                                                : "4px",
                                            height: "100%",
                                            width: "100%",
                                            maxHeight: 478,
                                            maxWidth: 358,
                                        }}
                                    />

                                    {hovered1 === true ? (
                                        <Box
                                            mb={0}
                                            className="icon-block"
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                left: 0,
                                                height: "125%",
                                                top: 0,
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                zIndex: -1,
                                                bottom: 0,
                                                width: "100%",
                                                borderRadius: "0px 0px 4px 4px",
                                                background: "white",
                                                boxShadow:
                                                    "0px 0px 20px 10px rgba(33, 29, 79, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
                                            }}
                                        >
                                            <Flex
                                                justify={"center"}
                                                align={"center"}
                                                direction={"column"}
                                            >
                                                <h2
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                        color: "#17365C",
                                                    }}
                                                >
                                                    Suman Parajuli
                                                </h2>
                                                <h2
                                                    style={{
                                                        color: "#17365C",
                                                        fontWeight: 600,
                                                        marginTop: 8,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    CEO
                                                </h2>
                                                <p
                                                    style={{
                                                        color: "#475569",
                                                        display: "flex",
                                                        gap: 4,
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    <IconMapPinFilled
                                                        size={16}
                                                        color="#475569"
                                                    />
                                                    Sydney, Australia
                                                </p>
                                            </Flex>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                        </Grid.Col>
                        <Grid.Col
                            md={4}
                            className="centered-col"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {" "}
                            <Box
                                onMouseEnter={() => {
                                    setHovered2(true);
                                }}
                                onMouseLeave={() => {
                                    setHovered2(false);
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "relative",
                                        margin: "auto",
                                        overflow: "visible",
                                        transition: "transform 0.3s ease",
                                        "&:hover": {
                                            transform: "scale(1.05)",
                                            ".overlay": {
                                                opacity: 1,
                                                transition: "opacity 0.3s ease",
                                            },
                                            ".content-details": {
                                                bottom: 10,
                                                right: 10,
                                                opacity: 1,
                                                transition:
                                                    "bottom 0.3s ease, right 0.3s ease, opacity 0.3s ease",
                                            },
                                        },
                                    }}
                                    className="content"
                                >
                                    <img
                                        src="/assets/teams/boy.png"
                                        alt=""
                                        loading="lazy"
                                        style={{
                                            borderRadius: hovered2
                                                ? "4px 4px 0px 0px"
                                                : "4px",
                                            height: "100%",
                                            width: "100%",
                                            maxHeight: 478,
                                            maxWidth: 358,
                                        }}
                                    />

                                    {hovered2 === true ? (
                                        <Box
                                            mb={0}
                                            className="icon-block"
                                            sx={{
                                                position: "absolute",
                                                right: 0,
                                                left: 0,
                                                height: "125%",
                                                top: 0,
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                zIndex: -1,
                                                bottom: 0,
                                                width: "100%",
                                                borderRadius: "0px 0px 4px 4px",
                                                background: "white",
                                                boxShadow:
                                                    "0px 0px 20px 10px rgba(33, 29, 79, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
                                            }}
                                        >
                                            <Flex
                                                justify={"center"}
                                                align={"center"}
                                                direction={"column"}
                                            >
                                                <h2
                                                    style={{
                                                        fontSize: 18,
                                                        fontWeight: 700,
                                                        color: "#17365C",
                                                    }}
                                                >
                                                    Bishal Gautam
                                                </h2>
                                                <h2
                                                    style={{
                                                        color: "#17365C",
                                                        fontWeight: 600,
                                                        marginTop: 8,
                                                        fontSize: 18,
                                                    }}
                                                >
                                                    Business Consultant
                                                </h2>
                                                <p
                                                    style={{
                                                        color: "#475569",
                                                        display: "flex",
                                                        gap: 4,
                                                        fontSize: 16,
                                                        fontWeight: 400,
                                                        alignItems: "center",
                                                        marginBottom: 10,
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    <IconMapPinFilled
                                                        size={16}
                                                        color="#475569"
                                                    />
                                                    Kathmandu, Nepal
                                                </p>
                                            </Flex>
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                        </Grid.Col>
                    </Grid>
                </Container>
            </Flex>
            <Container fluid className={classes.numbers} size={1630}>
                <Flex gap={55} direction={{ base: "column", xl: "row" }}>
                    <div className={classes.numberdiv}>
                        <IconHome
                            color="white"
                            style={{
                                height: "100%",
                                width: "100%",
                                maxHeight: 40,
                                maxWidth: 40,
                            }}
                        />
                        <p
                            style={{
                                color: "white",
                                fontSize: 20,
                                fontWeight: 400,
                                textAlign: "center",
                            }}
                        >
                            In House Product
                        </p>
                    </div>
                    <div className={classes.numberdiv}>
                        <IconBulb size={40} color="white" />
                        <p
                            style={{
                                color: "white",
                                fontSize: 20,
                                fontWeight: 400,
                                textAlign: "center",
                            }}
                        >
                            Innovative Approach
                        </p>
                    </div>
                    <div className={classes.numberdiv}>
                        <IconUsers size={40} color="white" />
                        <p
                            style={{
                                color: "white",
                                fontSize: 20,
                                fontWeight: 400,
                                textAlign: "center",
                            }}
                        >
                            Deliberative Team
                        </p>
                    </div>
                    <div className={classes.numberdiv}>
                        <IconInfoCircle size={40} color="white" />
                        <p
                            style={{
                                color: "white",
                                fontSize: 20,
                                fontWeight: 400,
                                textAlign: "center",
                            }}
                        >
                            Transformative Solutions
                        </p>
                    </div>
                </Flex>
            </Container>
            <Container size={"xl"}>
                {" "}
                <Grid
                    gutter={30}
                    className={classes.letsconnect}
                    mt={120}
                    p={"1rem"}
                >
                    <Grid.Col md={4}>
                        <h2 className={classes.ourteam}>
                            Our team is the{" "}
                            <span style={{ color: theme.colors.primary[0] }}>
                                Heartbeat
                            </span>{" "}
                            of our success
                        </h2>
                    </Grid.Col>
                    <Grid.Col md={7}>
                        <p
                            style={{
                                fontSize: 20,
                                fontWeight: 400,
                                color: theme.colors.brand[1],
                                marginBottom: 10,
                                textAlign: "justify",
                            }}
                        >
                            Our team consists of young professionals with an
                            innovative mindset in their respective fields and
                            are committed to delivering high quality solutions
                            within expected timeframe with utmost transparency.
                        </p>
                        <p
                            style={{
                                fontSize: 20,
                                fontWeight: 400,
                                color: theme.colors.brand[1],
                                marginBottom: 24,
                                textAlign: "justify",
                            }}
                        >
                            We invite you to join us on this exciting journey of
                            technological exploration. Explore our range of
                            services, each carefully designed to harness the
                            power of innovation and offer transformative
                            solutions across diverse sectors. Discover how we're
                            not just keeping pace with the future; we're shaping
                            it.
                        </p>
                        <Link to={"/careers"}>
                            <Flex
                                justify={"start"}
                                gap={10}
                                sx={{ cursor: "pointer" }}
                            >
                                <h2
                                    style={{
                                        fontSize: 18,
                                        fontWeight: 500,
                                        color: theme.colors.primary[0],
                                    }}
                                >
                                    JOIN OUR TEAM
                                </h2>
                                <IconArrowNarrowRight
                                    color={theme.colors.primary[0]}
                                />
                            </Flex>
                        </Link>
                    </Grid.Col>
                </Grid>
            </Container>
        </>
    );
};
export default AboutUs;
