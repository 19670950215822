import * as Yup from "yup";
import {
    emailValidationSchema,
    phoneNumberValidationSchema,
} from "./GlobalValidation";
import { stringUnReq } from "./GlobalValidation";

const FILE_SIZE = 1024 * 1024 * 4;
const SUPPORTED_FORMATS = [
    "application/pdf", //For .pdf
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // For .docx
    "application/msword", //For .doc
];

const fileUploadValidate = Yup.array()
    .length(1, "Required Field")
    .of(
        Yup.mixed()
            .test("fileFormat", "Unsupported file format", (value: any) => {
                return value && SUPPORTED_FORMATS.includes(value.type);
            })
            .test(
                "fileSize",
                "File too large , can only upload upto 4MB",
                (value: any) => {
                    return value && value.size <= FILE_SIZE;
                }
            )
    )
    .required("Required field");
export const careerformvalidationschema = Yup.object().shape({
    full_name: stringUnReq,
    email: emailValidationSchema,
    interested_position: stringUnReq,
    department: stringUnReq,
    resume: fileUploadValidate,
});

export default careerformvalidationschema;
