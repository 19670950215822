import { Button, Container, Flex } from "@mantine/core";
import Header from "./Header";
import { useHomePageHeaderStyles } from "../styles/components/HomePageheaderStyles";
import { Link } from "react-router-dom";
const HomePageHeader = () => {
    const { classes } = useHomePageHeaderStyles();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <>
            {/* Home Page Header */}
            <section>
                <Container size={"xxl"} fluid p={0}>
                    <div className={classes.root}>
                        <Header />
                        <Flex
                            justify={"center"}
                            align={"center"}
                            direction={"column"}
                            p={"1rem"}
                        >
                            <h2 className={classes.homepage_heading}>
                                Connecting service{" "}
                                <span style={{ color: "#0074ED" }}>
                                    elevating
                                </span>{" "}
                                experience
                            </h2>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: "white",
                                    textAlign: "center",
                                    marginBottom: 40,
                                }}
                            >
                                We believe in the strength of different
                                viewpoints and backgrounds. Our commitment to
                                inclusivity is matched by our dedication to
                                staying at the forefront of technology and
                                <br />
                                employing the most advanced methodologies. As we
                                innovate, we always uphold the highest ethical
                                standards, ensuring that our progress benefits
                                not only us but the world around us.
                            </p>
                            <Link to={"/contactus"} onClick={scrollToTop}>
                                <Button
                                    mb={110}
                                    size="xl"
                                    className={classes.homepage_button}
                                    pl={100}
                                    pr={100}
                                >
                                    Get In Touch
                                </Button>
                            </Link>
                        </Flex>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default HomePageHeader;
