import { MantineProvider } from "@mantine/core";
import type { ReactNode } from "react";
import { homaaleColors } from "./GlobalTheme";
import React from "react";
import { Notifications } from "@mantine/notifications";

const BaseThemeProvider = ({ children }: { children: ReactNode }) => {
    return (
        <MantineProvider
            theme={{ ...homaaleColors }}
            withGlobalStyles
            withNormalizeCSS
        >
            <Notifications limit={2} position="bottom-right" autoClose={3000} />
            {children}
        </MantineProvider>
    );
};

export default BaseThemeProvider;
