import { Container, useMantineTheme } from "@mantine/core";

const TermsConditions = () => {
    const theme = useMantineTheme();
    return (
        <Container mt={40} mb={100} size={1520}>
            {" "}
            <h1
                style={{
                    color: theme.colors.brand[0],
                    fontWeight: 800,
                    marginBottom: 40,
                }}
            >
                Terms and Conditions
            </h1>
            <h1
                style={{
                    color: theme.colors.brand[0],
                    fontWeight: 500,
                    marginBottom: 40,
                    fontSize: 14,
                }}
            >
                Please read these Terms and Conditions ("Terms") carefully
                before using the Cagtu’s website (the "Service") operated by
                Cagtu Pvt. Ltd. ("us," "we," or "our").
                <br />
                By accessing or using the Service, you agree to be bound by
                these Terms. If you disagree with any part of the Terms, please
                refrain from using our website.
            </h1>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Intellectual Property
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                The content, features, and functionality of the Service are the
                exclusive property of Cagtu and are protected by applicable
                copyright, trademark, and other intellectual property laws. You
                may not use our intellectual property without our explicit
                consent.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Website Access
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We grant you a limited, non-exclusive, and revocable access to
                our website for personal use. You must not engage in any
                activities that may harm, disrupt, or interfere with the
                functioning of the website.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                User Accounts
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                If the Service offers user account functionality, you are
                responsible for maintaining the confidentiality of your account
                and password.
                <br />
                You agree to provide accurate and complete information during
                the registration process.
                <br />
                We reserve the right to terminate or suspend your account at our
                discretion.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Privacy Policy
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Your use of the Service is also governed by our Privacy Policy.
                Please review our Privacy Policy to understand how we collect,
                use, and protect your personal information.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                External Links
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                The Service is provided "as is" without any warranties, express
                or implied.
                <br />
                We shall not be liable for any direct, indirect, incidental,
                special, or consequential damages arising from your use of the
                Service.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Governing Law
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                These Terms shall be governed and construed in accordance with
                the laws without regard to its conflict of law provisions.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Changes to Terms
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We reserve the right to update or revise these Terms at any
                time. Any changes will be posted on this page with an updated
                date.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Contact Information
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                For questions or concerns regarding these Terms, please contact
                us at info@cagtu.com
            </p>
        </Container>
    );
};
export default TermsConditions;
