import { createStyles } from "@mantine/styles";

export const useContactUsInfoStyles = createStyles((theme) => ({
    wrapper: {
        "& h3": {
            fontSize: 20,
            fontWeight: 500,
            color: "#1E293B",
            marginTop: 24,
        },
        "& p": {
            fontSize: 16,
            fontWeight: 400,
            color: theme.colors.primary[0],
            [theme.fn.smallerThan(750)]: {
                fontSize: 14,
            },
        },
    },
}));
