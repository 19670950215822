import { Container, Flex, Grid } from "@mantine/core";
import Header from "./Header";
import { useContactHeaderStyles } from "../styles/components/ContactHeaderStyles";
import ContactUsInfo from "./ContactUs/ContactUsInfo";
import {
    IconMailFilled,
    IconMapPinFilled,
    IconPhoneFilled,
} from "@tabler/icons-react";

const ContactHeader = () => {
    const { classes } = useContactHeaderStyles();
    return (
        <>
            {/* Contact Page Header */}
            <section>
                <Container
                    size={"xxl"}
                    p={0}
                    style={{ position: "relative" }}
                    mb={155}
                >
                    <div className={classes.root}>
                        <Header />
                        <Flex
                            justify={"center"}
                            align={"center"}
                            direction={"column"}
                            pl={"1rem"}
                            pr={"1rem"}
                            className={classes.contact_header}
                        >
                            <h2>Feel free to reach out to us</h2>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: "white",
                                    textAlign: "center",
                                }}
                            >
                                If there's anything we can assist you with,
                                please don't hesitate to let us know.
                            </p>
                        </Flex>
                    </div>
                    <div className={classes.contact_header_absolute}>
                        <Grid className={classes.contactusgrid} mb={0}>
                            <Flex
                                gap={140}
                                className={classes.contact_flex}
                                justify={"start"}
                            >
                                <ContactUsInfo
                                    title="Phone"
                                    number={"+65411654651"}
                                    icon={IconPhoneFilled}
                                />
                                <ContactUsInfo
                                    title={"Email"}
                                    number="info@cagtu.com"
                                    icon={IconMailFilled}
                                />
                                <ContactUsInfo
                                    title={"Location"}
                                    number=" NSW 2134, Australia"
                                    icon={IconMapPinFilled}
                                />
                            </Flex>
                        </Grid>
                    </div>
                </Container>
            </section>
        </>
    );
};
export default ContactHeader;
