import { createStyles } from "@mantine/styles";

export const useFooter = createStyles((theme) => ({
  upper_footer_background: {
    backgroundImage: "url(/assets/Products/background.jpg)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  upper_footer: {
    padding: "12px 0px 12px 0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& h2": {
      fontSize: 30,
      fontWeight: 600,
      fontFamily: "Manrope",
      color: "#F8FAFC",
      textTransform: "uppercase",
      [theme.fn.smallerThan(1535)]: {
        fontSize: 25,
      },
    },
  },
  absolute: {
    position: "absolute",
    marginRight: 110,
    background: "white",
    padding: "12px 26px 13px 25px",
    fontSize: 20,
    fontFamily: "Manrope",
    fontWeight: 500,
    color: "#182B34",
    borderRadius: "0px 9px 9px 0px",
  },
  input: {
    border: "1px solid white",
    padding: "10px 127px 10px 24px",
    borderRadius: "10px 10px 10px 10px",
    ".mantine-Input.Wrapper-description": {
      color: "red",
    },
  },
  uppergrid: {
    display: "flex",
    justifyContent: "end",
    [theme.fn.smallerThan(1000)]: {
      justifyContent: "start",
    },
  },
  relative: {
    position: "relative",
  },
  middle_footer: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(/assets/Footer/footer.png)",
  },
  footer_logo: {
    width: "100%",
    maxWidth: 123,
    marginBottom: 20,
  },
  footer_list: {
    ul: {
      li: {
        cursor: "pointer",
        color: "#CBD5E1",
        fontSize: 16,
        fontWeight: 400,
        marginTop: 12,
      },
    },
  },
  subscriptionback: {
    "& .mantine-Input-input": {
      background: "none",
      color: "white",
      borderRadius: "8px 14px 14px 8px",
    },
  },
  form_button: {
    position: "absolute",
    color: "black",
    borderRadius: "0px 12px 12px 0px",
    height: 40,
    background: "white",
    top: 1,
    zIndex: 0,
    right: 1,
  },
}));
