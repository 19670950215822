const cagtusite = "/cagtusite/";

const urls = {
    contactus: `${cagtusite}sitecontactus/`,
    newslettersubscribe: `${cagtusite}sitenewsletter/subscribe/`,
    newsletterunsubscribe: `${cagtusite}sitenewsletter/unsubscibe/`,
    vacancylist: `${cagtusite}sitevacancy/list/`,
    vacancypost: `${cagtusite}sitecareer/`,
};

export default urls;
