import { createStyles } from "@mantine/styles";

export const useIdeasStyles = createStyles((theme) => ({
    root: {
        borderRadius: 40,
        boxShadow:
            "0px 0px 20px 10px rgba(33, 29, 79, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        width: "100%",
        maxWidth: 487,

        height: 340,
        padding: "0px 50px 0px 50px",
        "& h2": {
            fontSize: 24,
            fontWeight: 700,
            color: theme.colors.brand[0],
            marginTop: 32,
            marginBottom: 24,
        },
        "& p": {
            fontSize: 16,
            textAlign: "center",
            fontWeight: 400,
            color: theme.colors.brand[1],
        },
    },
}));
