import { Container, useMantineTheme } from "@mantine/core";

const PrivacyPolicy = () => {
    const theme = useMantineTheme();
    return (
        <Container size={1520} mt={40} mb={120}>
            {" "}
            <h1
                style={{
                    color: theme.colors.brand[0],
                    fontWeight: 800,
                    marginBottom: 40,
                }}
            >
                Privacy Policy
            </h1>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Introduction
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Welcome to Cagtu ("we," "us," or "our"). At Cagtu, we respect
                your privacy and are committed to protecting your personal
                information. This Privacy Policy is designed to help you
                understand how we collect, use, disclose, and safeguard your
                personal information. By using our website, you consent to the
                practices described in this policy.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Information We Collect
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Personal Information: We may collect personal information, such
                as your name, email address, phone number, and any other
                information you voluntarily provide to us when you contact us or
                use our services.
                <br />
                Usage Information: We may collect information about your use of
                our website, including your IP address, browser type, pages
                viewed, and other analytics data.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                How We Use Your Information
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may use your personal information to communicate with you,
                respond to your inquiries, and provide you with information
                about our services.
                <br />
                We may use usage information to analyse trends, improve our
                website, and enhance the user experience.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Cookies and Tracking Technologies
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may use cookies and similar tracking technologies to collect
                information about your browsing behaviour. You can set your
                browser to refuse all or some browser cookies or to alert you
                when websites set or access cookies.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Disclosure of Your Information
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may share your personal information with third parties, such
                as service providers, contractors, or affiliates, who assist us
                in providing our services.
                <br />
                We may also disclose your personal information to comply with
                legal obligations, protect our rights, or respond to lawful
                requests.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Security
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We implement reasonable security measures to protect your
                personal information. However, no method of transmission over
                the internet is entirely secure, and we cannot guarantee the
                absolute security of your information.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Your Choices
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                You can choose not to provide us with certain information, but
                this may limit your ability to use certain features of our
                website.
                <br />
                You may opt out of receiving promotional communications from us
                by following the instructions in those communications.{" "}
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Childrens's Privacy
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                Our website is not intended for children under the age of 13. We
                do not knowingly collect personal information from children
                under 13. If you are under 13, please do not provide any
                information on our website.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Changes to This Policy
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated date.
            </p>
            <h2
                style={{
                    fontWeight: 600,
                    fontSize: 16,
                    color: theme.colors.brand[0],
                    marginBottom: 8,
                }}
            >
                Contact Information
            </h2>
            <p
                style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: theme.colors.brand[1],
                    marginBottom: 20,
                }}
            >
                If you have any questions or concerns regarding this Privacy
                Policy, please contact us at info@cagtu.com
            </p>
        </Container>
    );
};
export default PrivacyPolicy;
