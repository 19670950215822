import { createStyles } from "@mantine/styles";

export const useProductPageStyles = createStyles((theme) => ({
    product_heading: {
        "& h1": {
            fontSize: 40,
            fontFamily: "Manrope",
            fontWeight: 800,
            textAlign: "center",
            "& span": {
                color: theme.colors.primary[0],
            },
            [theme.fn.smallerThan(650)]: {
                fontSize: 30,
            },
            [theme.fn.smallerThan(450)]: {
                fontSize: 20,
            },
        },
    },
    mobileimage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
    },
    productGrid: {
        padding: "32px 38px 32px 38px",
        borderRadius: 30,
        backgroundColor: "rgba(228, 240, 255, 0.50)",

        "& h2": {
            fontSize: 24,
            fontWeight: 800,
            fontFamily: "Manrope",
            color: theme.colors.primary[0],
            marginBottom: 24,
        },
        "& p": {
            fontSize: 20,
            fontWeight: 400,
            lineHeight: "normal",
            fontStyle: "normal",
            color: theme.colors.brand[1],
            textAlign: "justify",
            marginBottom: 22,
        },
    },
    laptop: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        [theme.fn.smallerThan(1000)]: {
            marginTop: 60,
        },
    },
    webgrid: {
        padding: 30,
        borderRadius: 30,
        backgroundColor: "rgba(228, 240, 255, 0.50)",
        "& h2": {
            fontSize: 24,
            fontWeight: 800,
            fontFamily: "Manrope",
            color: theme.colors.primary[0],
            marginBottom: 24,
        },
        "& p": {
            fontSize: 20,
            fontWeight: 400,
            lineHeight: "normal",
            fontStyle: "normal",
            color: theme.colors.brand[1],
            textAlign: "justify",
            marginBottom: 22,
        },
        "& a": {
            fontSize: 20,
            fontFamily: "Manrope",
            fontWeight: 400,
            color: theme.colors.primary[0],
        },
    },
    end: {
        display: "flex",
        justifyContent: "space-between",
    },
}));
