import { Container, Flex, Grid } from "@mantine/core";
import { useProductPageStyles } from "../styles/pages/Product";

const Product = () => {
    const { classes } = useProductPageStyles();
    return (
        <>
            {/* Product Heading Section */}
            <section>
                <Container size={"xl"} mt={80} mb={100}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        className={classes.product_heading}
                    >
                        <h1>
                            Cagtu: Unlocking{" "}
                            <span> The Power Of Technology </span>And <br />
                            Simplifying Your Lives
                        </h1>
                    </Flex>
                </Container>
            </section>
            {/* Mobile App Section  */}
            <section>
                <Container size={1920} mb={120}>
                    <Grid align={"center"} justify="space-evenly" p={"1rem"}>
                        <Grid.Col md={4} p={0}>
                            <img
                                src="/assets/Products/Cagtu-Mobile.png"
                                alt=""
                                className={classes.mobileimage}
                            />
                        </Grid.Col>
                        <Grid.Col md={5} className={classes.productGrid}>
                            <h2>Cagtu.au -Mobile app</h2>
                            <p>
                                Embrace a simple, powerful and most dynamic
                                service based application at your fingertips. An
                                online platform where you can rely on us to find
                                diverse service providers as well as become a
                                service provider as an organization or a
                                freelancer. Experience the future of convenience
                                and simplify your needs.
                                <br />
                                Cagtu is available for download on all IOS and
                                Android devices, visit your respective app
                                store:
                            </p>
                            <Flex gap={28} justify={"start"} align={"center"}>
                                <img
                                    src="/assets/Products/Google_Play.png"
                                    alt=""
                                />
                                <img
                                    src="/assets/Products/App_Store.png"
                                    alt=""
                                />
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* Product Web App */}
            <section>
                <Container size={1920} mb={120}>
                    <Grid align="center" justify="space-evenly" p={"1rem"}>
                        <Grid.Col md={5} className={classes.webgrid}>
                            <h2>Cagtu.au -Web app</h2>
                            <p>
                                Cagtu.com.au website is a service platform
                                revolutionizing the way individuals connect with
                                skilled service providers. This web app likely
                                functions as a marketplace for task outsourcing,
                                making it easier for users to find suitable
                                service providers and get their tasks completed
                                efficiently and within a desired timeframe. The
                                tasks can range from household errands to more
                                complex projects, including software
                                development.
                            </p>
                            <a href="https://www.cagtu.com.au/">
                                Visit website :- Cagtu - Catering to Your
                                Requirements
                            </a>
                        </Grid.Col>
                        <Grid.Col md={4} p={0}>
                            <img
                                src="/assets/Products/laptop.png"
                                alt=""
                                className={classes.laptop}
                            />
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
        </>
    );
};
export default Product;
