import { createStyles } from "@mantine/styles";

export const useExtraHeaderStyles = createStyles((theme) => ({
    root: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
    },
    group: {
        position: "relative",
        padding: "20px 200px 20px 200px",
        [theme.fn.smallerThan(1500)]: {
            padding: "20px 100px 20px 100px",
        },
        [theme.fn.smallerThan(800)]: {
            padding: "20px 50px 20px 50px",
        },
        [theme.fn.smallerThan(550)]: {
            padding: "20px 20px 20px 20px",
        },
    },

    cagtulogo: {
        cursor: "pointer",
        [theme.fn.smallerThan(500)]: {
            width: 140,
        },
    },
    mobileMenuItems: {
        marginTop: 10,
        maxWidth: 1920,
        width: "100%",
        position: "absolute",
        marginLeft: "-80%",
    },
    mobileMenu: {
        ul: {
            padding: 0,
            width: "1920px",
            li: {
                color: "white",
                listStyle: "none",
                cursor: "pointer",
                background: "gray",
                width: "83%",
                maxWidth: 1920,
                fontWeight: 500,
                fontSize: 20,
            },
        },
    },
    headerItems: {
        ul: {
            display: "flex",
            gap: 40,
            li: {
                marginTop: 20,
                color: "white",
                listStyle: "none",
                cursor: "pointer",
                fontWeight: 500,
                fontSize: 20,
            },
        },
    },
}));
