import { Flex } from "@mantine/core";
import { useFocusInfoStyles } from "../styles/components/FocusInfoStyles";
import { Icon } from "@tabler/icons-react";
export interface FocusInfoProps {
  icon: Icon;
  title: string;
  desc: string;
}
const FocusInfo = ({ title, desc, icon: Icon }: FocusInfoProps) => {
  const { classes } = useFocusInfoStyles();
  return (
    <Flex align={"flex-start"} gap={16} mb={40}>
      <Flex className={classes.iconwrapper}>
        <Icon size={28} color="#0074ED" stroke={1.6} />
      </Flex>
      <div className={classes.focuswrapper}>
        <h2
          style={{
            fontSize: 20,
            fontFamily: "Manrope",
            fontWeight: 600,
            color: "var(--brand-color-secondary, #17365C)",
          }}
        >
          {title}
        </h2>
        <p style={{ fontSize: 16, fontWeight: 400, color: "#64748B" }}>
          {desc}
        </p>
      </div>
    </Flex>
  );
};
export default FocusInfo;
