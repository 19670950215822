import { createStyles } from "@mantine/styles";

export const useFocusInfoStyles = createStyles((theme) => ({
    iconwrapper: {
        padding: 12,
        background: "rgba(0, 116, 237, 0.06)",
        borderRadius: 8,
    },
    focuswrapper: {
        marginBottom: 14,
        marginTop: -10,
        "& h2": {
            color: theme.colors.brand[0],
        },
        "& p": {
            fontSize: 16,
            fontWeight: 400,
            color: theme.colors.brand[1],
        },
    },
}));
