import { RouterProvider } from "react-router-dom";
import routes from "./route";
import { QueryClient, QueryClientProvider } from "react-query";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";

function App() {
    const queryClient = new QueryClient();
    return (
        <div>
            <QueryClientProvider client={queryClient}>
                <RouterProvider router={routes} />
            </QueryClientProvider>
        </div>
    );
}

export default App;
