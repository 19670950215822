import { Flex } from "@mantine/core";
import { Icon } from "@tabler/icons-react";
export type HiringProcessProps = {
    title: string;
    desc: string;
    icon: Icon;
    color: string;
    color1: string;
};
const HiringProcess = ({
    title,
    desc,
    icon: Icon,
    color,
    color1,
}: HiringProcessProps) => {
    return (
        <Flex justify={"center"} align={"center"} gap={34} direction={"column"}>
            <Flex
                justify={"center"}
                align={"center"}
                sx={{
                    padding: 20,
                    background: color1,
                    borderRadius: 50,
                    zIndex: 90,
                }}
            >
                {" "}
                <Icon size={65} color={color} stroke={1} />
            </Flex>
            <Flex justify={"center"} align={"center"} direction={"column"}>
                <h3 style={{ fontSize: 20, fontWeight: 500, color: "#17365C" }}>
                    {title}
                </h3>
                <p
                    style={{
                        textAlign: "center",
                        fontSize: 16,
                        fontWeight: 400,
                        color: "#64748B",
                    }}
                >
                    {desc}
                </p>
            </Flex>
        </Flex>
    );
};
export default HiringProcess;
