import { createStyles } from "@mantine/styles";

export const useAboutUsPageStyles = createStyles((theme) => ({
    description: {
        padding: "0px 40px 0px 40px",
        h1: {
            textAlign: "center",
            fontWeight: 800,
            fontSize: 40,
            fontFamily: "Manrope",
            [theme.fn.smallerThan(700)]: {
                fontSize: 30,
            },
        },
        p: {
            fontWeight: 500,
            fontSize: 16,
            color: "#64748B",
        },
    },
    productImage: {
        width: "100%",
        borderRadius: 10,
        height: "100%",
        objectFit: "cover",
        maxHeight: 410,
    },

    productInfo: {
        borderRadius: 30,
        background: "rgba(228, 240, 255, 0.5)",
        padding: "65px 40px 65px 40px",
        h2: {
            fontSize: 24,
            marginBottom: 10,
            fontWeight: 700,
            textAlign: "start",
            color: theme.colors.brand[0],
        },
        p: {
            fontSize: 16,
            marginBottom: 20,
            fontWeight: 400,
            textAlign: "justify",
        },
    },
    connect: {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        gap: 10,
        h4: {
            margin: 0,
            fontSize: 16,
            fontWeight: 700,
            color: "#335eea",
        },
    },
    aim: {
        background:
            "linear-gradient(180deg, #F5FAFF 0%, rgba(245, 250, 255, 0.00) 100%)",
        padding: "100px 10px 100px 10px",
    },
    vision: {
        maxWidth: 486,
        width: "100%",
    },
    focusgroup: {
        padding: "0px 100px 0px 100px",
        justifyContent: "space-between",
        display: "flex",
    },
    gridimage: {
        width: "100%",
        height: "auto",
    },
    images: {
        height: 478,
        maxWidth: 357,
        width: "100%",
        objectFit: "cover",
    },
    numbers: {
        padding: "50px 100px 50px 100px",
        backgroundColor: "#17365C",
    },
    numberdiv: {
        borderRadius: 4,
        border: "0.5px solid var(--tailwind-500, #64748B)",
        boxShadow: "0px 0px 20px 10px rgba(255, 255, 255, 0.10)",
        padding: "0px 24px 0px 50px",
        display: "flex",
        width: "100%",
        maxWidth: 338,
        height: 140,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: 140,
        gap: 16,
    },
    letsconnect: {
        marginBottom: 120,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    ourteam: {
        fontSize: 40,
        fontWeight: 600,
        color: theme.colors.brand[0],
        [theme.fn.smallerThan(650)]: {
            fontSize: 30,
            textAlign: "center",
        },
    },
}));
