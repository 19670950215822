import { Container, Flex, Grid, useMantineTheme } from "@mantine/core";
import { useHomePageStyles } from "../styles/pages/HomePageStyles";
import Ideas from "../components/Home/Ideas";
import { IconEyeCheck, IconTargetArrow } from "@tabler/icons-react";
import { IconDiamond } from "tabler-icons";

const Home = () => {
    const theme = useMantineTheme();
    const { classes } = useHomePageStyles();
    return (
        <>
            {/* Suman Sir Section */}
            <section>
                <Container
                    size={1920}
                    fluid
                    className={classes.suman_background}
                >
                    <Flex
                        align={"flex-start"}
                        sx={{ padding: "102px 441px 0px 441px" }}
                        className={classes.quatation}
                    >
                        <img
                            src="/quatation.png"
                            alt=""
                            className={classes.quatation_image}
                        />
                        <Flex
                            pt={38}
                            gap={59}
                            justify={"center"}
                            align={"center"}
                            direction={"column"}
                        >
                            <p
                                style={{
                                    color: theme.colors.brand[1],
                                    textAlign: "center",
                                    fontWeight: 500,
                                }}
                                className={classes.homepagepara}
                            >
                                I've consistently marvelled at how technology is
                                reshaping even the minutest aspects of our
                                world. In response, we've assembled a dynamic,
                                youthful team that collectively dedicates itself
                                to manifesting concepts into reality through
                                Cagtu. By unlocking limitless possibilities,
                                we're creating a realm where innovation thrives
                                boundlessly, breaking down barriers and defying
                                limitations.
                            </p>
                            <Flex
                                justify={"center"}
                                align={"center"}
                                direction={"column"}
                            >
                                <img
                                    src="/assets/Home/suman-parajuli.png"
                                    alt="suman_sir_here"
                                    className={classes.ceo_image}
                                />
                                <h3
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 600,
                                        marginTop: 24,
                                        color: theme.colors.brand[0],
                                    }}
                                >
                                    Suman Parajuli
                                </h3>
                                <p
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: theme.colors.brand[1],
                                        marginBottom: 90,
                                    }}
                                >
                                    CEO - Sydney, Australia
                                </p>
                            </Flex>
                        </Flex>
                    </Flex>
                </Container>
            </section>
            {/* Who are we section */}
            <section>
                <Container size={"xxl"} mb={120}>
                    <Grid
                        justify="center"
                        align="center"
                        sx={{
                            background:
                                "linear-gradient(180deg, #F5FAFF 0%, rgba(245, 250, 255, 0.00) 100%)",
                        }}
                        p={"1rem"}
                    >
                        <Grid.Col md={4}>
                            <img
                                src="/assets/Home/discussion.png"
                                alt=""
                                className={classes.discussion_image}
                            />
                        </Grid.Col>
                        <Grid.Col md={5}>
                            <h2 className={classes.wedo}>Who are we?</h2>
                            <p
                                style={{
                                    color: theme.colors.brand[1],
                                    fontWeight: 400,
                                    fontSize: 20,
                                    textAlign: "justify",
                                }}
                            >
                                As a startup, our vision propels us to venture
                                boldly into the realm of services, armed with
                                technology to simplify your lives. Our focus on
                                customer-centric solutions drives us to craft
                                products that put you at the core of innovation.
                                We're not just aiming for a place at the
                                forefront; we're aiming to lead. Our philosophy
                                revolves around turning ideas into tangible
                                impact, weaving change and progress with every
                                step.
                            </p>
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* Transforming Ideas Section */}
            <section>
                <Container size={"xxl"} mb={120}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        gap={100}
                        direction={"column"}
                    >
                        <Flex
                            justify={"center"}
                            align={"center"}
                            direction={"column"}
                        >
                            <h2 className={classes.transformideas} style={{}}>
                                “Transforming Ideas Into Impact”
                            </h2>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    color: theme.colors.brand[1],
                                    textAlign: "center",
                                }}
                            >
                                We are a creative team of designers, developers,
                                database engineers and project managers working
                                <br />
                                together to build amazing products and services
                                for end-users.
                            </p>
                        </Flex>
                        <Flex
                            gap={30}
                            align={"center"}
                            direction={{ base: "column", md: "row" }}
                        >
                            <Ideas
                                title="MISSION"
                                borderColor={"#ED3F00"}
                                desc="Through continuous research and innovative concepts, our plan is to provide solutions for modern day problems with the use of technology."
                                icon={IconTargetArrow}
                                color={"#ED3F00"}
                            />
                            <Ideas
                                title="VISION"
                                borderColor={"#01CBD5"}
                                desc="We aspire to become the leading company that empowers technology and digitalization to provide exciting solutions in various sectors."
                                icon={IconEyeCheck}
                                color={"#01CBD5"}
                            />
                            <Ideas
                                title="VALUES"
                                borderColor="#FFB400"
                                desc="Every solution we provide or the innovation we make is made with utmost research and hardwork. We know nothing is impossible, but not everything is easy either."
                                icon={IconDiamond}
                                color={"#FFB400"}
                            />
                        </Flex>
                    </Flex>
                </Container>
            </section>
            {/* What we do section */}
            <section>
                <Container size={"xxl"} mb={120}>
                    <Grid
                        justify="center"
                        align="center"
                        sx={{
                            background:
                                "linear-gradient(0deg, #F5FAFF 0.25%, rgba(245, 250, 255, 0.00) 84.88%)",
                        }}
                        p={"1rem"}
                    >
                        <Grid.Col md={5}>
                            <h2 className={classes.wedo}>What we do?</h2>
                            <p
                                style={{
                                    color: theme.colors.brand[1],
                                    fontWeight: 400,
                                    fontSize: 20,
                                    textAlign: "justify",
                                }}
                            >
                                As a software company, our goal is to completely
                                transform the digital world. Our goal is to
                                develop ground-breaking products and services
                                that enable people and organisations to prosper
                                in the contemporary world. We enhance the ease,
                                effectiveness, and seamless integration of your
                                digital experience with cutting-edge software
                                and disruptive technology. We work to make
                                technology accessible and usable for everyone in
                                an effort to realise its full potential. At our
                                core, we are committed to forming bonds, closing
                                gaps, and putting all the services you require
                                at your disposal.
                            </p>
                        </Grid.Col>
                        <Grid.Col md={4}>
                            <img
                                src="/assets/Home/whatwedo.png"
                                alt=""
                                className={classes.discussion_image}
                            />
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* How Company works */}
            <section>
                <Container fluid mb={160}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        direction={"column"}
                    >
                        <h2 className={classes.transformideas}>
                            How Our Company Works
                        </h2>
                        <p
                            style={{
                                color: "black",
                                fontWeight: 400,
                                fontSize: 20,
                                marginBottom: 50,
                                textAlign: "center",
                            }}
                        >
                            At Cagtu, we pride ourselves on our dynamic approach
                            to delivering exceptional solutions.
                        </p>
                        <img
                            src="/assets/chart.png"
                            alt=""
                            className={classes.workimage}
                        />
                    </Flex>
                </Container>
            </section>
        </>
    );
};
export default Home;
