import { createStyles } from "@mantine/styles";

export const useHomePageStyles = createStyles((theme) => ({
    homepagepara: {
        fontSize: 24,
        textIndent: 30,
        [theme.fn.smallerThan(550)]: {
            fontSize: 18,
        },
    },
    quatation_image: {
        height: "100%",
        width: "100%",
        maxHeight: 93,
        maxWidth: 93,
        [theme.fn.smallerThan("md")]: {
            height: 60,
            width: 60,
            textIndent: 10,
            textAlign: "justify",
        },
        [theme.fn.smallerThan(500)]: {
            display: "none",
        },
    },
    suman_background: {
        backgroundImage: "url(/assets/Home/ceo-background.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
    },
    quatation: {
        position: "relative",

        [theme.fn.smallerThan(1600)]: {
            padding: "150px 241px 0px 241px",
        },
        [theme.fn.smallerThan(1300)]: {
            padding: "150px 201px 0px 201px",
        },
        [theme.fn.smallerThan(1100)]: {
            padding: "150px 141px 0px 141px",
        },
        [theme.fn.smallerThan(900)]: {
            padding: "100px 41px 0px 41px",
        },
        [theme.fn.smallerThan(700)]: {
            padding: "100px 21px 0px 21px",
        },
    },
    ceo_image: {
        objectFit: "cover",
        borderRadius: 50,
        height: 164,
        width: 164,
    },
    discussion_image: {
        objectFit: "cover",
        borderRadius: 50,
        width: "100%",
        height: "100%",
        maxHeight: 606,
        maxwidth: 743,
    },
    wedo: {
        color: theme.colors.primary[0],
        fontWeight: 700,
        fontSize: 40,
        marginBottom: 24,

        [theme.fn.smallerThan(600)]: {
            fontSize: 30,
            textAlign: "center",
        },
    },
    transformideas: {
        fontSize: 40,
        fontWeight: 700,
        color: theme.colors.brand[0],
        marginBottom: 32,
        textAlign: "center",
        [theme.fn.smallerThan(600)]: {
            fontSize: 30,
        },
    },

    workimage: {
        maxWidth: 1428,
        width: "100%",
        height: "100%",
        maxHeight: 555,
    },
}));
