import { createStyles } from "@mantine/styles";

export const useContactUsStyles = createStyles((theme) => ({
    contact_title: {
        fontSize: 40,
        fontWeight: 800,
        color: theme.colors.brand[0],
        marginBottom: 8,
        [theme.fn.smallerThan(600)]: {
            fontSize: 25,
        },
    },
}));
