import type { FC } from "react";
import { Box } from "@mantine/core";
import { useLandingLayoutStyles } from "../styles/components/LandingLayoutStyles";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import HomePageHeader from "./HomePageHeader";

const HomeLandingLayout: FC = () => {
    const { classes } = useLandingLayoutStyles();
    return (
        <Box className={classes.root}>
            <header>
                <HomePageHeader />
            </header>
            <main>
                <Outlet />
            </main>
            <footer>
                <Footer />
            </footer>
        </Box>
    );
};
export default HomeLandingLayout;
