import { createStyles } from "@mantine/styles";

export const useHomePageHeaderStyles = createStyles((theme) => ({
    root: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: "url(/assets/Home/homepage-background.png)",
    },
    homepage_button: {
        border: "1px solid white",
        borderRadius: 8,
        color: "white",
        width: "100%",
        maxWidth: 344,
    },
    homepage_heading: {
        marginTop: 500,
        fontSize: 60,
        fontWeight: 800,
        color: "white",
        textAlign: "center",
        marginBottom: 24,
        [theme.fn.smallerThan(1600)]: {
            marginTop: 300,
        },
        [theme.fn.smallerThan(1200)]: {
            marginTop: 200,
        },
        [theme.fn.smallerThan(1000)]: {
            marginTop: 150,
        },
        [theme.fn.smallerThan(800)]: {
            marginTop: 100,
            fontSize: 40,
        },
    },
}));
