import { Flex } from "@mantine/core";
import { useButtonStyles } from "../../styles/components/ButtonStyle";
export interface TableButtonProps {
    name: string;
}
const TableButton = ({ name }: TableButtonProps) => {
    const { classes } = useButtonStyles();
    return (
        <Flex justify={"center"} align={"center"} className={classes.root}>
            <h3>{name}</h3>
        </Flex>
    );
};
export default TableButton;
