import { Container, Flex, Grid } from "@mantine/core";
import { useFooter } from "../styles/components/Footer";
import * as Yup from "yup";
import {
    IconBrandFacebook,
    IconBrandInstagram,
    IconBrandLinkedin,
} from "tabler-icons";
import { Link } from "react-router-dom";
import InputField from "./InputField";
import FormButton from "./common/FormButton";
import { Formik, Form } from "formik";
import { emailValidationSchema } from "../utils/validation.ts/GlobalValidation";
import { useMutation } from "react-query";
import { axiosClient } from "../utils/axiosClient";
import { newsletter } from "../utils/newsletter";
import urls from "../utils/urls";
import { toast } from "./common/Toast";

const Footer = () => {
    const { classes } = useFooter();
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const emailSubscription = useMutation((data) =>
        axiosClient.post(urls.newslettersubscribe, data)
    );
    const onSubscribeEmail = (data: any, actions: any) => {
        emailSubscription.mutate(data, {
            onSuccess: (data) => {
                if (data?.data?.status === "failure") {
                    toast.error(data?.data?.message);
                } else {
                    toast.success(data?.data?.message);
                    actions.resetForm();
                }
            },
            onError: (error: any) => {
                toast.error(error?.response?.data?.email[0]);
                actions.resetForm();
            },
        });
    };
    return (
        <>
            {/* Footer Newletter Section */}
            <section>
                <Container
                    fluid
                    className={classes.upper_footer_background}
                    m={0}
                >
                    <Grid className={classes.upper_footer}>
                        <Grid.Col md={4} className={classes.uppergrid}>
                            <h2>Subscribe to our newsletter</h2>
                        </Grid.Col>
                        <Grid.Col md={4} className={classes.relative}>
                            <Formik
                                initialValues={newsletter}
                                validationSchema={Yup.object().shape({
                                    email: emailValidationSchema,
                                })}
                                onSubmit={async (values, actions) => {
                                    onSubscribeEmail(values, actions);
                                }}
                            >
                                {({ isSubmitting, errors, touched }) => (
                                    <Form
                                        style={{
                                            position: "relative",
                                        }}
                                    >
                                        <InputField
                                            style={{
                                                margin: 0,
                                            }}
                                            className={classes.subscriptionback}
                                            type="email"
                                            error={errors.email as string}
                                            touch={touched.email as boolean}
                                            name="email"
                                            placeholder="Your email address"
                                        />

                                        <FormButton
                                            className={classes.form_button}
                                            type="submit"
                                            size={"sm"}
                                            name="Subscribe"
                                            disabled={isSubmitting}
                                            id={"newsletter"}
                                            maxwidth={"114px"}
                                        />
                                    </Form>
                                )}
                            </Formik>
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* Footer Middle Section */}
            <section className={classes.middle_footer}>
                <Container size={1520} pt={50} mt={15}>
                    <img
                        src="/assets/Footer/footerlogo.png"
                        alt=""
                        className={classes.footer_logo}
                    />
                    <Grid
                        sx={{
                            color: "white",
                            width: "100%",
                            maxWidth: 1520,
                        }}
                    >
                        <Grid.Col md={5}>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: 40,
                                    color: "#CBD5E1",
                                }}
                            >
                                Cagtu is an organisation that specialises in
                                providing a wide range of technology-related
                                products and services.
                            </p>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: 40,
                                    color: "#CBD5E1",
                                }}
                            >
                                Email : info@cagtu.com
                            </p>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: 40,
                                    color: "#CBD5E1",
                                }}
                            >
                                Australia Office
                                <br />
                                <span style={{ fontWeight: 600 }}>
                                    706 2A Elsie, StreetBurwood , NSW 2134,
                                    Australia
                                </span>
                            </p>
                            <p
                                style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    marginBottom: 40,
                                    color: "#CBD5E1",
                                }}
                            >
                                {" "}
                                Nepal Office
                                <br />
                                <span style={{ fontWeight: 600 }}>
                                    Balkumari,Lalitpur,Nepal
                                </span>
                            </p>
                        </Grid.Col>
                        <Grid.Col
                            md={2}
                            m={0}
                            p={0}
                            className={classes.footer_list}
                        >
                            <ul
                                style={{
                                    listStyle: "none",
                                    paddingLeft: 12,
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        marginBottom: 20,
                                        color: "white",
                                    }}
                                >
                                    Useful Links
                                </h3>
                                <Link to={"/"} onClick={scrollToTop}>
                                    <li>Home</li>
                                </Link>

                                <Link to={"/about-us"} onClick={scrollToTop}>
                                    {" "}
                                    <li>About Us</li>
                                </Link>
                                <Link to={"/products"} onClick={scrollToTop}>
                                    <li>Products</li>
                                </Link>

                                <Link to={"/careers"} onClick={scrollToTop}>
                                    <li>Careers</li>
                                </Link>
                                <Link to={"/contact-us"} onClick={scrollToTop}>
                                    {" "}
                                    <li>Contact Us</li>
                                </Link>
                            </ul>
                        </Grid.Col>
                        <Grid.Col
                            md={2}
                            m={0}
                            p={0}
                            className={classes.footer_list}
                        >
                            <ul
                                style={{
                                    listStyle: "none",
                                    paddingLeft: 12,
                                }}
                            >
                                <h3
                                    style={{
                                        fontSize: 20,
                                        fontWeight: 600,
                                        marginBottom: 20,
                                        color: "white",
                                    }}
                                >
                                    Legal
                                </h3>
                                <Link
                                    to={"/terms-conditions"}
                                    onClick={scrollToTop}
                                >
                                    {" "}
                                    <li>Terms and Conditions</li>
                                </Link>{" "}
                                <Link
                                    to={"/privacy-policy"}
                                    onClick={scrollToTop}
                                >
                                    <li>Privacy Policy</li>
                                </Link>
                                <Link
                                    to={"/cookies-policy"}
                                    onClick={scrollToTop}
                                >
                                    {" "}
                                    <li>Cookies Policy</li>
                                </Link>{" "}
                            </ul>
                        </Grid.Col>
                        <Grid.Col md={3}>
                            <h3
                                style={{
                                    fontSize: 20,
                                    fontWeight: 600,
                                    marginBottom: 20,
                                    color: "white",
                                }}
                            >
                                Social Media
                            </h3>
                            <Flex justify={"flex-start"} gap={30}>
                                {" "}
                                <Link to={"/"}>
                                    <IconBrandLinkedin
                                        size={24}
                                        color="#CBD5E1"
                                        cursor={"pointer"}
                                    />
                                </Link>
                                <Link
                                    to="https://www.instagram.com/homaaleservices/"
                                    target={"_blank"}
                                >
                                    {" "}
                                    <IconBrandInstagram
                                        size={24}
                                        color="#CBD5E1"
                                        cursor={"pointer"}
                                    />
                                </Link>
                                <Link
                                    to="https://www.facebook.com/cagtuaustralia"
                                    target={"_blank"}
                                >
                                    {" "}
                                    <IconBrandFacebook
                                        size={24}
                                        color="#CBD5E1"
                                        cursor={"pointer"}
                                    />
                                </Link>
                            </Flex>
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* Lower Footer */}
            <section>
                <Flex
                    justify={"center"}
                    align={"center"}
                    sx={{
                        background: "#102743",
                        color: "white",
                        borderTop: "0.5px solid  rgba(248, 250, 252, 0.8)",
                        padding: "16px 0px 16px 0px",
                    }}
                >
                    <p
                        style={{
                            fontWeight: 700,
                            fontSize: 14,
                            color: "#CBD5E1",
                        }}
                    >
                        © Copyright Cagtu Australia. All Rights Reserved
                    </p>
                </Flex>
            </section>
        </>
    );
};
export default Footer;
