import type { ButtonProps } from "@mantine/core";
import { Button, Loader } from "@mantine/core";

import { FormButtonProps } from "../../types/ButtonProps";

const FormButton = ({
    tabIndex,
    name,
    isSubmitting,
    maxwidth,
    handleClick,
    id,
    disabled,
    ...restProps
}: FormButtonProps & ButtonProps) => {
    return (
        <Button
            {...restProps}
            tabIndex={tabIndex}
            id={`form-button${id ? id : name}`}
            onClick={handleClick}
            disabled={isSubmitting || disabled}
            sx={{
                width: "100%",
                maxWidth: maxwidth,
                margin: "0 auto",
            }}
        >
            {!isSubmitting ? (
                <span>{name}</span>
            ) : (
                <span>
                    <Loader size="sm" />
                </span>
            )}
        </Button>
    );
};

export default FormButton;
