import { createStyles } from "@mantine/styles";

export const useCareerDetailStyles = createStyles((theme) => ({
    root: {
        ul: {
            paddingLeft: 20,
            li: {
                fontWeight: 400,
                fontSize: 16,
                marginBottom: 4,
            },
        },
    },
}));
