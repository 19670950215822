import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../axiosClient";
import urls from "../urls";
import { VacancyformProps } from "../../types/VacancyformProps";

export const useForm = () => {
    return useMutation<any, Error, VacancyformProps>(async (vacancyPayload) => {
        const formData = new FormData();
        Object.entries(vacancyPayload).forEach((entry) => {
            const [key, value] = entry;
            formData.append(key, value);
        });
        vacancyPayload.resume.forEach((file) =>
            formData.append("resume", file)
        );
        const { data } = await axiosClient.post(urls.vacancypost, formData);
        return data;
    });
};
