import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../axiosClient";
import urls from "../urls";
import { ApplicationFormValueProps } from "../../types/CareerInitialProps";

export const useVacantForm = () => {
    return useMutation<any, Error, ApplicationFormValueProps>(
        async (dropcvpayload) => {
            const formData = new FormData();
            Object.entries(dropcvpayload).forEach((entry) => {
                const [key, value] = entry;
                formData.append(key, value);
            });
            dropcvpayload.resume.forEach((file) =>
                formData.append("resume", file)
            );
            const { data } = await axiosClient.post(urls.vacancypost, formData);
            return data;
        }
    );
};
