import { useMutation } from "@tanstack/react-query";
import { axiosClient } from "../axiosClient";
import { ContactUsProps } from "../../types/ContactUsProps";
import urls from "../urls";

export const useContact = () => {
    return useMutation<any, Error, ContactUsProps>(async (contactPayload) => {
        const { data } = await axiosClient.post(urls.contactus, contactPayload);
        return data;
    });
};
