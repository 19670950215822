import { Box, Text, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const Empty = ({
    title,
    description,
}: {
    title: string;
    description: string;
    btnTitle?: string;
    link?: string;
}) => {
    const theme = useMantineTheme();
    const smallScreen = useMediaQuery("(max-width: 36em)");

    return (
        <Box
            sx={{
                marginInline: "auto",
                textAlign: "center",
            }}
        >
            <figure
                style={{
                    position: "relative",
                    width: !smallScreen ? 480 : 240,
                    height: !smallScreen ? 420 : 120,
                    marginInline: "auto",
                }}
            >
                <img
                    src="/no_data.svg"
                    alt="asdf"
                    style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                    }}
                />
            </figure>
            <Title
                order={4}
                sx={{
                    fontWeight: 500,
                    color: theme.colors[theme.primaryColor][5],
                }}
                mt={30}
                mb={8}
            >
                {title}
            </Title>
            <Text
                component="p"
                sx={{
                    fontSize: 16,
                    color: theme.colors.gray[6],
                }}
            >
                {description}
            </Text>
        </Box>
    );
};

export default Empty;
