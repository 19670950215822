import type { FC } from "react";
import { Box } from "@mantine/core";
import { useLandingLayoutStyles } from "../styles/components/LandingLayoutStyles";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import ContactHeader from "./ContactHeader";

const ContactLandingLayout: FC = () => {
  const { classes } = useLandingLayoutStyles();
  return (
    <Box className={classes.root}>
      <header style={{ position: "relative" }}>
        <ContactHeader />
      </header>
      <main>
        <Outlet />
      </main>
      <footer>
        <Footer />
      </footer>
    </Box>
  );
};
export default ContactLandingLayout;
