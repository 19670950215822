import { createStyles } from "@mantine/styles";

export const useButtonStyles = createStyles((theme) => ({
    root: {
        padding: "5px 10px 5px 10px",
        width: 188,
        backgroundColor: "rgba(0, 116, 237, 0.10)",
        borderRadius: 4,
        [theme.fn.smallerThan(500)]: {
            padding: "5px 5px 5px 5px",
            width: 100,
        },
        "& h3": {
            fontSize: 18,
            fontWeight: 500,
            fontFamily: "Manrope",
            color: theme.colors.primary[0],
            [theme.fn.smallerThan(500)]: {
                fontSize: 12,
            },
        },
    },
}));
