import { Flex } from "@mantine/core";
import { useIdeasStyles } from "../../styles/components/Ideas";
import { Icon } from "@tabler/icons-react";
export type IdeasProps = {
    title: string;
    desc: string;
    icon: Icon;
    color: string;
    borderColor: string;
};
const Ideas = ({ title, desc, color, icon: Icon, borderColor }: IdeasProps) => {
    const { classes } = useIdeasStyles();
    return (
        <Flex
            className={classes.root}
            sx={{ borderTop: `5px solid ${borderColor}` }}
            align={"center"}
            justify={"center"}
            direction={"column"}
        >
            <Icon size={68} color={color} />
            <h2>{title}</h2>
            <p style={{ textAlign: "justify" }}>{desc}</p>
        </Flex>
    );
};
export default Ideas;
