import { createBrowserRouter } from "react-router-dom";
import LandingLayout from "./components/LandingLayout";
import AboutUs from "./pages/About-us";
import Product from "./pages/Product";
import Career from "./pages/Career";
import ContactLandingLayout from "./components/ContactLandingLayout";
import ContactUs from "./pages/Contact-Us";
import HomeLandingLayout from "./components/HomeLandingLayout";
import Home from "./pages/Home";
import CareerDetail from "./pages/Career/[id]";
import TermsConditions from "./pages/Terms-Conditions";
import CareerForm from "./pages/Career/form";
import PrivacyPolicy from "./pages/Privacy-Policy";
import CookiesPolicy from "./pages/CookiesPolicy";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <HomeLandingLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "about-us",
        element: <AboutUs />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "products",
        element: <Product />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "careers",
        element: <Career />,
      },
      {
        path: ":slug",
        element: <CareerDetail />,
      },
      {
        path: "form",
        element: <CareerForm />,
      },
    ],
  },
  {
    path: "/",
    element: <ContactLandingLayout />,
    children: [
      {
        path: "contact-us",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "/terms-conditions",
        element: <TermsConditions />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy />,
      },
    ],
  },
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "/cookies-policy",
        element: <CookiesPolicy />,
      },
    ],
  },
]);


export default routes;
