// Get current Year
export const getCurrentYear = () => {
    return new Date().getFullYear();
};

// form-group validate
export const checkFormGroup = (error: any) => {
    const currentClass = "form-group";
    const errorClass = "validate";
    return error ? `${currentClass} ${errorClass}` : currentClass;
};

// form-control validate
export const checkFormControl = (error: any, touched: any) => {
    const currentClass = "form-control";
    const errorClass = "is-invalid";
    return error && touched ? `${currentClass} ${errorClass}` : currentClass;
};

// Form Button Submitting
export const isSubmittingClass = (isSubmitting: boolean) => {
    const defaultClass = `btn site-btn`;
    const submittingClass = `btn site-btn cf-spinner cf-spinner--center cf-spinner--sm isSubmitting`;
    return isSubmitting ? submittingClass : defaultClass;
};

// Date formatter
export const formatMonthDate = (dateString: any) => {
    const dateArray = new Date(String(dateString)).toDateString().split(" ");
    return `${dateArray[2]} ${dateArray[1]}, ${dateArray[3]}`;
};

export const phoneRegExp =
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export const getPageUrl = () => {
    return typeof window != "undefined" ? window.location.href : "";
};

export const getApiEndpoint = () => {
    const url = process.env.REACT_APP_API_URL;
    if (url === undefined)
        throw new Error(
            "Please specify an API endpoint in the environment variable REACT_APP_API_URL"
        );

    return url;
};
