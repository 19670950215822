import { createStyles } from "@mantine/styles";

export const useCareerWorkStyles = createStyles((theme) => ({
    icon_wrapper: {
        background: "rgba(0, 116, 237, 0.10)",
        padding: 3,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 50,
    },
    title: {
        "& h1": {
            marginTop: -6,
            fontSize: 20,
            fontFamily: "Manrope",
            fontWeight: 500,
            marginBottom: 10,
            textAlign: "start",
            color: theme.colors.brand[0],
        },
        "& p": {
            fontSize: 16,
            fontFamily: "Manrope",
            fontWeight: 300,
            color: theme.colors.brand[1],
        },
    },
}));
