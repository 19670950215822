import type { TextareaProps } from "@mantine/core";
import { Textarea } from "@mantine/core";
import { useMantineTheme } from "@mantine/core";
import type { FieldProps } from "formik";
import { Field } from "formik";

import { InputFieldProps } from "../types/InputFieldProps";

const DescriptionField = ({
    name,
    error,
    touch,
    minRows,
    maxRows,
    marginIgnore,
    ...rest
}: InputFieldProps & TextareaProps) => {
    const errTouch = error && touch ? error : null;
    return (
        <Field name={name}>
            {({ field, form }: FieldProps) => {
                return (
                    <Textarea
                        {...field}
                        {...rest}
                        mb={marginIgnore ? 0 : 24}
                        error={errTouch}
                        radius="md"
                        autosize
                        minRows={minRows ?? 4}
                        maxRows={maxRows ?? 6}
                        size="md"
                        onBlur={(e) => {
                            form.setFieldValue(name, e.target.value.trim());
                            form.setFieldTouched(name, true);
                        }}
                    />
                );
            }}
        </Field>
    );
};

export default DescriptionField;
