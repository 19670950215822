import { createStyles } from "@mantine/styles";

export const useContactHeaderStyles = createStyles((theme) => ({
    root: {
        backgroundImage: "url(/assets/Contact/contact-background.png)",
    },
    contact_header: {
        paddingTop: 80,
        textAlign: "center",
        paddingBottom: 190,

        "& h2": {
            fontSize: 40,
            fontWeight: 800,
            color: "white",
            marginBottom: 8,
            [theme.fn.smallerThan(440)]: { fontSize: 25 },
        },
    },
    contactusgrid: {
        padding: "24px 150px 24px 150px",
        [theme.fn.smallerThan(1200)]: {
            padding: "24px 50px 24px 50px",
        },
        [theme.fn.smallerThan(450)]: {
            padding: "24px 20px 24px 20px",
        },
        boxShadow:
            "0px 0px 20px 10px rgba(33, 29, 79, 0.10), 0px 0px 4px 0px rgba(0, 0, 0, 0.10)",
        background: "white",
        borderRadius: 16,
    },
    contact_header_absolute: {
        position: "absolute",
        left: "50%",
        bottom: "-15%",
        transform: "translateX(-50%)",
    },
    contact_flex: {
        [theme.fn.smallerThan(750)]: {
            gap: 15,
        },
    },
}));
