import * as Yup from "yup";

import {
    emailValidationSchema,
    phoneNumberValidationSchema,
} from "./GlobalValidation";
import { stringUnReq } from "./GlobalValidation";

export const contactFormSchema = Yup.object().shape({
    first_name: stringUnReq,
    last_name: stringUnReq,
    phone: phoneNumberValidationSchema,
    email: emailValidationSchema,
    message: stringUnReq,
});

export default contactFormSchema;
