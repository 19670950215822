import { Container, Box, Flex, useMantineTheme, Drawer } from "@mantine/core";
import { useHeader } from "../styles/components/Header";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { IconMenu2 } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
const Header = () => {
    const { classes } = useHeader();
    const [toggle, setToggle] = useState(false);
    const mediumScreen = useMediaQuery("(min-width: 1100px)");

    // Function to toggle the menu visibility
    const theme = useMantineTheme();
    const toggleMenu = () => {
        setToggle(!toggle);
    };

    const location = useLocation();
    return (
        <Box className={classes.root}>
            <Container size={"xxl"} fluid>
                <Flex className={classes.group}>
                    <Link to={"/"}>
                        <img
                            src="/assets/cagtulogo.png"
                            className={classes.cagtulogo}
                            alt="cagtu-logo"
                        />
                    </Link>

                    {mediumScreen ? (
                        <div className={classes.headerItems}>
                            <ul>
                                <Link to={"/"}>
                                    <li
                                        style={{
                                            color:
                                                location.pathname === "/"
                                                    ? theme.colors.primary[0]
                                                    : "white",
                                        }}
                                    >
                                        Home
                                    </li>
                                </Link>
                                <Link to={"/about-us"}>
                                    <li
                                        style={{
                                            margin: 0,
                                            color:
                                                location.pathname ===
                                                "/about-us"
                                                    ? theme.colors.primary[0]
                                                    : "white",
                                        }}
                                    >
                                        About Us
                                    </li>
                                </Link>
                                <Link to={"/products"}>
                                    <li
                                        style={{
                                            margin: 0,
                                            color:
                                                location.pathname ===
                                                "/products"
                                                    ? theme.colors.primary[0]
                                                    : "white",
                                        }}
                                    >
                                        Products
                                    </li>
                                </Link>
                                <Link to={"/careers"}>
                                    <li
                                        style={{
                                            margin: 0,
                                            color:
                                                location.pathname === "/careers"
                                                    ? theme.colors.primary[0]
                                                    : "white",
                                        }}
                                    >
                                        Careers
                                    </li>
                                </Link>{" "}
                                <Link to={"/contact-us"}>
                                    <li
                                        style={{
                                            margin: 0,
                                            color:
                                                location.pathname ===
                                                "/contact-us"
                                                    ? theme.colors.primary[0]
                                                    : "white",
                                        }}
                                    >
                                        Contact Us
                                    </li>
                                </Link>
                            </ul>
                        </div>
                    ) : (
                        <div className={classes.mobileMenu}>
                            <IconMenu2
                                size={24}
                                onClick={toggleMenu}
                                color="white"
                            />
                            {toggle && (
                                <Drawer
                                    opened={toggle}
                                    size={"45%"}
                                    position="top"
                                    overlayProps={{ opacity: 0.5, blur: 4 }}
                                    onClose={toggleMenu}
                                    title={
                                        <img
                                            src="/assets/cagtulogo1.png"
                                            className={classes.cagtulogo1}
                                            alt="cagtu-logo"
                                            width={140}
                                        />
                                    }
                                >
                                    <Flex>
                                        <ul style={{ listStyle: "none" }}>
                                            <Link to={"/"}>
                                                {" "}
                                                <li
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            "/"
                                                                ? theme.colors
                                                                      .primary[0]
                                                                : "black",
                                                    }}
                                                >
                                                    Home
                                                </li>
                                            </Link>
                                            <Link to={"/about-us"}>
                                                <li
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            "/about-us"
                                                                ? theme.colors
                                                                      .primary[0]
                                                                : "black",
                                                    }}
                                                >
                                                    About Us
                                                </li>
                                            </Link>
                                            <Link to={"/products"}>
                                                <li
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            "/products"
                                                                ? theme.colors
                                                                      .primary[0]
                                                                : "black",
                                                    }}
                                                >
                                                    Products
                                                </li>
                                            </Link>
                                            <Link to={"/careers"}>
                                                <li
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            "/careers"
                                                                ? theme.colors
                                                                      .primary[0]
                                                                : "black",
                                                    }}
                                                >
                                                    Careers
                                                </li>
                                            </Link>{" "}
                                            <Link to={"/contact-us"}>
                                                <li
                                                    style={{
                                                        color:
                                                            location.pathname ===
                                                            "/contact-us"
                                                                ? theme.colors
                                                                      .primary[0]
                                                                : "black",
                                                    }}
                                                >
                                                    Contact Us
                                                </li>
                                            </Link>
                                        </ul>
                                    </Flex>
                                </Drawer>
                            )}
                        </div>
                    )}
                </Flex>
            </Container>
        </Box>
    );
};
export default Header;
