import {
    Container,
    Grid,
    Flex,
    Table,
    useMantineTheme,
    Button,
    Modal,
} from "@mantine/core";
import Empty from "../../components/common/Empty";
import { useNavigate } from "react-router-dom";
import { CarrerFormData } from "../../utils/careerinitialdata";
import { useCareerStyles } from "../../styles/pages/CareerStyles";
import CareerWork from "../../components/CareerWork";
import {
    IconChevronRight,
    IconLogin,
    IconMessages,
    IconMoodHappy,
} from "tabler-icons";
import { IconListDetails, IconMapPinFilled } from "@tabler/icons-react";
import TableButton from "../../components/common/Button";
import HiringProcess from "../../components/HiringProcess";
import { useState } from "react";
import InputField from "../../components/InputField";
import { Form, Formik } from "formik";
import FormButton from "../../components/common/FormButton";
import FileInputField from "../../components/FileInputFieldProps";
import urls from "../../utils/urls";
import { axiosClient } from "../../utils/axiosClient";
import { useEffect } from "react";
import { ContactVacancyProps } from "../../types/ContactusVacancyProps";
import SelectField from "../../components/SelectField";
import { useVacantForm } from "../../utils/hooks/useVacantForm";
import { toast } from "../../components/common/Toast";
import careerformvalidationschema from "../../utils/validation.ts/careerformvalidationschema";
import { useMediaQuery } from "@mantine/hooks";
import Asterik from "../../components/ContactUs/Asterisk";

const Career = () => {
    const [careerData, setCareerData] = useState<ContactVacancyProps>();
    const options = [
        { id: 0, value: "Frontend Developer", label: "Frontend Developer" },
        { id: 1, value: "Backend Developer", label: "Backend Developer" },
    ];
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const { mutate } = useVacantForm();
    const [openmodal, setOpenmodal] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient.get(urls.vacancylist);
                const { data } = response;
                if (data.error) {
                    throw new Error(data.error.message);
                }
                setCareerData(data);
            } catch (err) {
                console.error(err);
                setCareerData(careerData);
            }
        };
        fetchData();
    }, []);
    const navigate = useNavigate();
    const { classes } = useCareerStyles();
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const smallScreen = useMediaQuery("(max-width: 600px)");
    const rows = careerData?.result?.map((element: any) => (
        <tr key={element.id}>
            <td
                style={{
                    fontSize: smallScreen ? 12 : 20,
                    fontWeight: smallScreen ? 300 : 450,
                    color: "#1E293B",
                }}
            >
                {element.title}
            </td>
            <td
                style={{
                    fontSize: smallScreen ? 12 : 20,
                    fontWeight: smallScreen ? 300 : 400,
                    color: "#1E293B",
                }}
            >
                {" "}
                <Flex align={"center"} justify={"flex-start"} gap={16}>
                    <IconMapPinFilled size={24} />
                    {element.location}
                </Flex>
            </td>
            <td>
                <TableButton name={element.job_type} />
            </td>
            <td>
                <button
                    onClick={() => {
                        navigate(`/${element.slug}`, {
                            state: {
                                description: element.description,
                                id: element.id,
                                department: element.category,
                                interested_position: element.title,
                            },
                        });
                        scrollToTop();
                    }}
                    style={{ border: "none", background: "none" }}
                >
                    <IconChevronRight height={30} width={20} />
                </button>
            </td>
        </tr>
    ));
    return (
        <>
            {/* Career Heading */}
            <section>
                <Container size={1255} mt={80} mb={80}>
                    <div className={classes.career_heading}>
                        <h2>
                            JOIN OUR <span>TEAM</span>
                        </h2>
                        <p>
                            Join our team of young professionals and become a
                            part in transforming the world using technology. We
                            specialise in research, software development,
                            business and design.
                        </p>
                    </div>
                </Container>
            </section>
            {/* Work with us */}
            <section>
                <Container size={1520} mb={120}>
                    <div className={classes.work}>
                        <h2>Why work with us ?</h2>
                        <Grid grow>
                            <Grid.Col md={4} p={0}>
                                <CareerWork
                                    title="Working Environment"
                                    desc="Friendly and supportive work environment."
                                />
                            </Grid.Col>
                            <Grid.Col md={4} p={0}>
                                <CareerWork
                                    title="Competitive Salary"
                                    desc="Salary competitive to the market"
                                />
                            </Grid.Col>
                            <Grid.Col md={4} p={0}>
                                <CareerWork
                                    title="Support for resources"
                                    desc="Allowance for any resources required"
                                />
                            </Grid.Col>
                            <Grid.Col md={4} p={0} mt={48}>
                                <CareerWork
                                    title="Celebration of Efforts"
                                    desc="We recognize your contribution"
                                />
                            </Grid.Col>
                            <Grid.Col md={4} p={0} mt={48}>
                                <CareerWork
                                    title="Leave"
                                    desc="Paid leaves like floating leaves, sick leaves, maternity leaves etc"
                                />
                            </Grid.Col>
                            <Grid.Col md={4} p={0} mt={48}>
                                <CareerWork
                                    title="Engagement and Events"
                                    desc="Team building and recreation events"
                                />
                            </Grid.Col>
                        </Grid>
                    </div>
                </Container>
            </section>
            {/* Recent Openings */}
            <section>
                <Container size={1520} mb={120}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        direction={"column"}
                        className={classes.recent_openings}
                    >
                        <h2>Recent Openings</h2>
                        {careerData?.result &&
                        careerData?.result?.length > 0 ? (
                            <Table
                                verticalSpacing={"md"}
                                highlightOnHover
                                striped
                            >
                                <tbody>{rows}</tbody>
                            </Table>
                        ) : (
                            <Empty
                                title={"No Vacancy For Now !!!"}
                                description={""}
                            />
                        )}
                    </Flex>
                </Container>
            </section>
            {/* Hiring Process */}
            <section>
                {" "}
                <Container size={1520} mb={120}>
                    <Flex gap={16} direction={"column"} mb={40}>
                        <h2
                            style={{
                                fontSize: 24,
                                fontWeight: 600,
                                color: theme.colors.brand[0],
                            }}
                        >
                            Our Hiring Process
                        </h2>
                        <p>Just need four steps</p>
                    </Flex>
                    <Grid justify="space-between" sx={{ position: "relative" }}>
                        {/* <img
                            src="/assets/line.png"
                            alt=""
                            style={{
                                position: "absolute",
                                left: 178,
                                height: 1,
                                top: 67,
                                maxWidth: 1161,
                                width: "100%",
                                opacity: 0.4,
                            }}
                            className={classes.line_image}
                        /> */}
                        <Grid.Col md={2}>
                            <HiringProcess
                                title="Apply"
                                desc="Apply for your position by forwarding CV via email."
                                icon={IconLogin}
                                color="#B1AB64"
                                color1="rgba(254, 249, 195, 0.50)"
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            {" "}
                            <HiringProcess
                                title="Complete Task"
                                desc="Certain task should be
                                performed for evaluation"
                                icon={IconListDetails}
                                color="#AF66FF"
                                color1="rgba(243, 232, 255, 0.50)"
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            {" "}
                            <HiringProcess
                                color1="rgba(181, 242, 242, 0.30)"
                                title="Interview"
                                desc="To know more details about
                                what you are keen to do."
                                icon={IconMessages}
                                color="#408B8B"
                            />
                        </Grid.Col>
                        <Grid.Col md={2}>
                            {" "}
                            <HiringProcess
                                color1="rgba(220, 252, 231, 0.60)"
                                title="Welcome"
                                desc="You are recruited after all
                                successful process."
                                icon={IconMoodHappy}
                                color="#1B8040"
                            />
                        </Grid.Col>
                    </Grid>
                </Container>
            </section>
            {/* Drop your cv section */}
            <section>
                <Container size="xxl" mb={120}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        direction={"column"}
                        pt={100}
                        pb={100}
                        className={classes.eyeglass}
                    >
                        <img src="/assets/Career/eyeglass.png" alt="" />
                        <h2 className={classes.not_find_position}>
                            Didn’t find your preferred position ?
                        </h2>
                        <p
                            style={{
                                color: theme.colors.brand[1],
                                fontSize: 16,
                                fontWeight: 400,
                                textAlign: "center",
                                marginBottom: 80,
                            }}
                        >
                            We’re always looking for high performing and
                            energetic individual. If you are one, please submit
                            your resume.
                        </p>
                        <Button
                            pl={40}
                            pr={40}
                            sx={{
                                border: "1px solid #0074ED",
                                color: "#0074ED",
                            }}
                            onClick={() => setOpened(true)}
                        >
                            Drop your CV
                        </Button>
                    </Flex>
                </Container>
            </section>
            {opened && (
                <Modal
                    opened={opened}
                    closeOnClickOutside={false}
                    onClose={() => setOpened(false)}
                    centered
                    radius={"md"}
                    size={1085}
                >
                    <Flex
                        justify={"center"}
                        align={"center"}
                        direction={"column"}
                    >
                        <h1
                            style={{
                                fontSize: 40,
                                fontWeight: 800,
                                color: theme.colors.brand[0],
                                marginBottom: 8,
                            }}
                        >
                            Fill up the form
                        </h1>
                        <p
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                color: theme.colors.brand[1],
                                marginBottom: 80,
                            }}
                        >
                            Kindly provide the details below, and if you meet
                            our criteria, we will reach out to you.
                        </p>
                    </Flex>
                    <Formik
                        initialValues={CarrerFormData}
                        validationSchema={careerformvalidationschema}
                        onSubmit={(values, action) => {
                            mutate(values, {
                                onSuccess: () => {
                                    setOpened(false);
                                    toast.success("CV successfully submitted.");
                                    setOpenmodal(true);
                                    action.resetForm();
                                },
                                onError: (error: any) => {
                                    toast.error(error.message);
                                },
                            });
                        }}
                    >
                        {({
                            setFieldValue,
                            values,
                            isSubmitting,
                            errors,
                            touched,
                        }) => (
                            <Form
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: 20,
                                }}
                            >
                                <Grid grow>
                                    <Grid.Col md={6}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Full Name
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="text"
                                            name="full_name"
                                            error={errors.full_name as string}
                                            touch={touched.full_name as boolean}
                                            placeholder="Full Name"
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Email
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="email"
                                            name="email"
                                            error={errors.email as string}
                                            touch={touched.email as boolean}
                                            placeholder="Your email address"
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} pt={0} mb={10}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Interested Department
                                            <Asterik />
                                        </h3>
                                        <SelectField
                                            withAsterisk
                                            name={"department"}
                                            error={errors.department as string}
                                            touch={
                                                touched.department as boolean
                                            }
                                            searchable
                                            data={options}
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} pt={0} mb={10}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Interested Position
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="text"
                                            name="interested_position"
                                            error={
                                                errors.interested_position as string
                                            }
                                            touch={
                                                touched.interested_position as boolean
                                            }
                                            placeholder="Position"
                                        />
                                    </Grid.Col>
                                </Grid>
                                <h3
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#1E293B",
                                        marginBottom: 4,
                                    }}
                                >
                                    Resume/CV
                                    <Asterik />
                                </h3>
                                <FileInputField
                                    name="resume"
                                    touch={touched.resume as boolean}
                                    placeHolder="Attach Resume/CV"
                                    handleChange={(e) => {
                                        setFieldValue(
                                            "resume",
                                            Array.from(e.target.files)
                                        );
                                        setFieldValue(
                                            "imagePreviewUrl",
                                            e.target.files[0].name
                                        );
                                    }}
                                    fileName={values.imagePreviewUrl}
                                    desc={
                                        "Accepted Files: DOC, DOCX, PDF upto 4MB"
                                    }
                                />

                                <FormButton
                                    mt={100}
                                    type="submit"
                                    size={"md"}
                                    maxwidth="394px"
                                    disabled={isSubmitting}
                                    name={"Submit"}
                                    id={"newsletter-btn"}
                                    style={{
                                        background: theme.colors.primary[0],
                                        color: "#fff",
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                </Modal>
            )}
            {openmodal && (
                <Modal
                    size={480}
                    opened={opened}
                    onClose={() => setOpenmodal(false)}
                >
                    <Flex
                        justify={"Center"}
                        align={"center"}
                        direction={"column"}
                        pb={50}
                        pr={50}
                        pl={50}
                    >
                        <img
                            src="/assets/Modal/modal_tick.png"
                            alt=""
                            style={{
                                width: "100%",
                                maxWidth: 126,
                                height: "100%",
                                maxHeight: 126,
                                objectFit: "cover",
                                marginBottom: 24,
                            }}
                        />
                        <p style={{ textAlign: "center" }}>
                            CV successfully submitted.
                        </p>
                    </Flex>
                </Modal>
            )}
        </>
    );
};
export default Career;
