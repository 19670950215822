import { createStyles } from "@mantine/styles";

export const useCareerStyles = createStyles((theme) => ({
    career_heading: {
        "& h2": {
            textAlign: "center",
            marginBottom: 16,
            fontSize: 40,
            fontWeight: 800,
            fontFamily: "Manrope",
            color: theme.colors.brand[0],
            "& span": {
                color: theme.colors.primary[0],
            },
            [theme.fn.smallerThan(650)]: {
                fontSize: 30,
            },
        },
        "& p": {
            textAlign: "center",
            fontSize: 16,
            fontWeight: 400,
            color: theme.colors.brand[1],
        },
    },
    work: {
        background: "#F4F9FF",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 30,
        padding: "75px 180px 75px 180px",
        [theme.fn.smallerThan(800)]: {
            padding: "75px 160px 75px 100px",
        },
        [theme.fn.smallerThan(550)]: {
            padding: "75px 60px 75px 60px",
        },
        "& h2": {
            textAlign: "center",
            fontSize: 28,
            fontWeight: 700,
            marginBottom: 70,
            fontFamily: "Manrope",
            color: theme.colors.brand[0],
            marginBotton: 48,
        },
    },
    recent_openings: {
        "& h2": {
            fontSize: 24,
            fontWeight: 600,
            color: theme.colors.brand[0],
            fontFamily: "Manrope",
            marginBottom: 40,
        },
    },
    not_find_position: {
        fontSize: 40,
        fontWeight: 800,
        color: theme.colors.brand[0],
        marginBottom: 16,
        textAlign: "center",
        [theme.fn.smallerThan(650)]: {
            fontSize: 30,
        },
    },
    eyeglass: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundImage: "url(/assets/Career/language.png)",
    },
    line_image: {
        [theme.fn.smallerThan(1470)]: {
            display: "none",
        },
    },
}));
