import type { TextInputProps } from "@mantine/core";
import { TextInput } from "@mantine/core";
import type { FieldProps } from "formik";
import { Field } from "formik";

import { InputFieldProps } from "../types/InputFieldProps";

const InputField = ({
    name,
    error,
    touch,
    marginIgnore,
    ref,
    ...rest
}: InputFieldProps &
    TextInputProps &
    React.RefAttributes<HTMLInputElement>) => {
    const errTouch = error && touch ? error : null;
    return (
        <Field name={name} ref={ref}>
            {({ field, form }: FieldProps) => {
                return (
                    <TextInput
                        {...field}
                        {...rest}
                        mb={marginIgnore ? 0 : 24}
                        error={errTouch}
                        radius="md"
                        size="md"
                        onBlur={(e) => {
                            form.setFieldValue(
                                name,
                                e.currentTarget.value.trim()
                            );
                            form.setFieldTouched(name, true);
                        }}
                    />
                );
            }}
        </Field>
    );
};

export default InputField;
