import {
    Container,
    Divider,
    Flex,
    Grid,
    Modal,
    useMantineTheme,
} from "@mantine/core";
import { Form, Formik } from "formik";
import InputField from "../../components/InputField";
import FileInputField from "../../components/FileInputFieldProps";
import FormButton from "../../components/common/FormButton";
import DescriptionField from "../../components/DescriptionField";
import { toast } from "../../components/common/Toast";
import { useForm } from "../../utils/hooks/useForm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { VacancyformProps } from "../../types/VacancyformProps";
import { useState } from "react";
import careerformvalidationschema from "../../utils/validation.ts/careerformvalidationschema";
import Asterik from "../../components/ContactUs/Asterisk";
import PhoneNumberField from "../../components/common/PhoneNumberField";
const CareerForm = () => {
    const theme = useMantineTheme();
    const { mutate } = useForm();
    const { state } = useLocation();
    const [opened, setOpened] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <section>
                <Flex
                    justify={"center"}
                    align={"center"}
                    direction={"column"}
                    mt={60}
                >
                    <h2
                        style={{
                            fontWeight: 700,
                            fontSize: 40,
                            color: theme.colors.brand[0],
                        }}
                    >
                        JOIN OUR TEAM
                    </h2>
                    <p
                        style={{
                            marginTop: 8,
                            fontWeight: 400,
                            textAlign: "center",
                            fontSize: 16,
                            color: theme.colors.brand[1],
                            marginBottom: 60,
                        }}
                    >
                        We firmly embrace the journey of growth
                    </p>
                </Flex>
                <Container size={1520}>
                    <Formik
                        enableReinitialize
                        validationSchema={careerformvalidationschema}
                        initialValues={
                            {
                                full_name: "",
                                phone: "",
                                email: "",
                                resume: [],
                                cover_letter: "",
                                experience: 0,
                                expected_salary: "",
                                interested_position: state.interested_position,
                                department: state.department,
                                imagePreviewUrl: [],
                            } as VacancyformProps
                        }
                        onSubmit={(values, action) => {
                            mutate(values, {
                                onSuccess: () => {
                                    toast.success(
                                        "You have successfully applied at Cagtu"
                                    );
                                    action.resetForm();
                                    navigate("/careers");
                                },
                                onError: (error: any) => {
                                    toast.error(error.message);
                                },
                            });
                        }}
                    >
                        {({
                            setFieldValue,
                            values,
                            isSubmitting,
                            errors,
                            touched,
                        }) => (
                            <Form
                                style={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                }}
                            >
                                <Divider />
                                <Grid p={50}>
                                    <Grid.Col md={2}>
                                        <h2
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Your Basic Information
                                        </h2>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            {" "}
                                            Tell us about Yourself.
                                        </p>
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Full Name
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="text"
                                            name="full_name"
                                            error={errors.full_name as string}
                                            touch={touched.full_name as boolean}
                                            placeholder="Full Name"
                                            withAsterisk
                                        />
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Email Address
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="email"
                                            name="email"
                                            error={errors.email as string}
                                            touch={touched.email as boolean}
                                            placeholder="Your email address"
                                            withAsterisk
                                        />
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Phone
                                        </h3>
                                        <PhoneNumberField
                                            name="phone"
                                            id="phone"
                                            placeHolder="Enter Your Phone Number"
                                            error={errors.phone as string}
                                            touch={touched.phone as boolean}
                                            onChange={(value) =>
                                                setFieldValue("phone", value)
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Divider />
                                <Grid p={50}>
                                    <Grid.Col md={2}>
                                        <h2
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Flaunt your experience
                                        </h2>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Upload your CV or resume
                                        </p>
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Resume/CV
                                            <Asterik />
                                        </h3>
                                        <FileInputField
                                            name="resume"
                                            error={errors.resume as string}
                                            touch={touched.resume as boolean}
                                            placeHolder="Attach Resume/CV"
                                            handleChange={(e) => {
                                                setFieldValue(
                                                    "resume",
                                                    Array.from(e.target.files)
                                                );
                                                setFieldValue(
                                                    "imagePreviewUrl",
                                                    e.target.files[0].name
                                                );
                                            }}
                                            fileName={values.imagePreviewUrl}
                                            desc={
                                                "Accepted Files: DOC, DOCX, PDF upto 4MB"
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Divider />
                                <Grid p={50}>
                                    <Grid.Col md={2}>
                                        <h2
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Let’s hear more about your skills.
                                        </h2>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Submit your cover letter
                                        </p>
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        <DescriptionField
                                            minLength={20}
                                            id="description"
                                            name={"cover_letter"}
                                            touch={
                                                touched.cover_letter as boolean
                                            }
                                            error={
                                                errors.cover_letter as string
                                            }
                                            minRows={8}
                                            fieldrequired
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                </Grid>
                                <Divider />
                                <Grid p={50}>
                                    <Grid.Col md={2}>
                                        <h2
                                            style={{
                                                fontWeight: 600,
                                                fontSize: 16,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            For better understanding
                                        </h2>
                                        <p
                                            style={{
                                                fontWeight: 400,
                                                fontSize: 14,
                                                color: theme.colors.brand[0],
                                            }}
                                        >
                                            Fill in the blanks
                                        </p>
                                    </Grid.Col>
                                    <Grid.Col md={8}>
                                        <InputField
                                            label="Years of experience"
                                            type="text"
                                            name="experience"
                                            error={errors.experience as string}
                                            touch={
                                                touched.experience as boolean
                                            }
                                        />
                                        <InputField
                                            label="Expected Salary"
                                            type="text"
                                            name="expected_salary"
                                            error={
                                                errors.expected_salary as string
                                            }
                                            touch={
                                                touched.expected_salary as boolean
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>

                                <FormButton
                                    mt={116}
                                    type="submit"
                                    mb={86}
                                    size={"md"}
                                    maxwidth="387px"
                                    disabled={isSubmitting}
                                    name={"Submit Application"}
                                    id={"newsletter-btn"}
                                    style={{
                                        background: theme.colors.primary[0],
                                        color: "#fff",
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                </Container>
            </section>
            {opened && (
                <Modal
                    size={480}
                    opened={opened}
                    onClose={() => setOpened(false)}
                >
                    <Flex
                        justify={"Center"}
                        align={"center"}
                        direction={"column"}
                        pb={50}
                        pr={50}
                        pl={50}
                    >
                        <img
                            src="/assets/Modal/modal_tick.png"
                            alt=""
                            style={{
                                width: "100%",
                                maxWidth: 126,
                                height: "100%",
                                maxHeight: 126,
                                objectFit: "cover",
                                marginBottom: 24,
                            }}
                        />
                        <p style={{ textAlign: "center" }}>
                            Application Successfully Sent!
                        </p>
                    </Flex>
                </Modal>
            )}
        </>
    );
};
export default CareerForm;
