import { Flex, useMantineTheme } from "@mantine/core";
import { Icon } from "@tabler/icons-react";
import { useContactUsInfoStyles } from "../../styles/components/ContactUsInfoStyles";
export type ContactUsCompProps = {
    title: string;
    number: string;
    icon: Icon;
};
const ContactUsInfo = ({ title, number, icon: Icon }: ContactUsCompProps) => {
    const theme = useMantineTheme();
    const { classes } = useContactUsInfoStyles();
    return (
        <Flex
            justify={"center"}
            align={"center"}
            direction={"column"}
            className={classes.wrapper}
        >
            <Icon size={32} style={{ color: theme.colors.primary[0] }} />

            <h3>{title}</h3>
            <p>{number}</p>
        </Flex>
    );
};
export default ContactUsInfo;
