import { Container, Flex, Grid, Modal, useMantineTheme } from "@mantine/core";
import { Form, Formik } from "formik";
import InputField from "../components/InputField";
import PhoneNumberField from "../components/common/PhoneNumberField";
import FormButton from "../components/common/FormButton";
import { contactusformdata } from "../utils/contactinitialdata";
import DescriptionField from "../components/DescriptionField";
import { useContact } from "../utils/hooks/ContactUs";
import contactFormSchema from "../utils/validation.ts/ContactUs";
import { useState } from "react";
import { useContactUsStyles } from "../styles/pages/ContactUsStyles";
import Asterik from "../components/ContactUs/Asterisk";

const ContactUs = () => {
    const [opened, setOpened] = useState(false);
    const { mutate } = useContact();
    const theme = useMantineTheme();
    const { classes } = useContactUsStyles();
    return (
        <>
            {/* Contact Us Info */}
            <section>
                <Container size={1005} mb={200}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        direction={"column"}
                    >
                        <h1 className={classes.contact_title}>Reach Us Out</h1>
                        <p
                            style={{
                                fontSize: 16,
                                fontWeight: 400,
                                color: theme.colors.brand[1],
                                marginBottom: 60,
                                textAlign: "center",
                            }}
                        >
                            For further queries including partnership
                            opportunities, please send us an email to
                            info@cagtu.com or contact us using the form below.
                        </p>
                    </Flex>
                    <Formik
                        initialValues={contactusformdata}
                        validationSchema={contactFormSchema}
                        onSubmit={async (values, action) => {
                            mutate(values, {
                                onSuccess: async (data) => {
                                    setOpened(true);
                                    action.resetForm();
                                },

                                onError: (error: any) => {
                                    const {
                                        first_name,
                                        last_name,
                                        email,
                                        message,
                                        phone,
                                    } = error.response.data;
                                    action.setFieldError(
                                        "full_name",
                                        first_name && first_name[0]
                                    );
                                    action.setFieldError(
                                        "full_name",
                                        last_name && last_name[0]
                                    );
                                    action.setFieldError(
                                        "phone",
                                        phone && phone[0]
                                    );
                                    action.setFieldError(
                                        "email",
                                        email && email[0]
                                    );
                                    action.setFieldError(
                                        "message",
                                        message && message[0]
                                    );
                                },
                            });
                        }}
                    >
                        {({ setFieldValue, isSubmitting, errors, touched }) => (
                            <Form
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    padding: 20,
                                }}
                            >
                                <Grid>
                                    <Grid.Col md={6} pb={0}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            First Name
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="text"
                                            name="first_name"
                                            error={errors.first_name as string}
                                            touch={
                                                touched.first_name as boolean
                                            }
                                            placeholder="Full Name"
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} pb={0}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Last Name
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="text"
                                            name="last_name"
                                            error={errors.last_name as string}
                                            touch={touched.last_name as boolean}
                                            placeholder="Last Name"
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} mb={16}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Phone Number
                                        </h3>
                                        <PhoneNumberField
                                            name="phone"
                                            id="phone"
                                            placeHolder="Enter Your Phone Number"
                                            error={errors.phone as string}
                                            touch={touched.phone as boolean}
                                            onChange={(value) =>
                                                setFieldValue("phone", value)
                                            }
                                        />
                                    </Grid.Col>
                                    <Grid.Col md={6} mb={16}>
                                        <h3
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: "#1E293B",
                                                marginBottom: 4,
                                            }}
                                        >
                                            Email
                                            <Asterik />
                                        </h3>
                                        <InputField
                                            type="email"
                                            name="email"
                                            error={errors.email as string}
                                            touch={touched.email as boolean}
                                            placeholder="Enter Your Email"
                                            withAsterisk
                                        />
                                    </Grid.Col>
                                </Grid>
                                <h3
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 500,
                                        color: "#1E293B",
                                        marginBottom: 4,
                                    }}
                                >
                                    How may we be of assistance to you?
                                    <Asterik />
                                </h3>
                                <DescriptionField
                                    minLength={20}
                                    id="description"
                                    name={"message"}
                                    placeholder={"Describe your queries."}
                                    touch={touched.message as boolean}
                                    error={errors.message as string}
                                    minRows={8}
                                />
                                <FormButton
                                    maxwidth="394px"
                                    mt={100}
                                    type="submit"
                                    size={"md"}
                                    disabled={isSubmitting}
                                    name={"Send Message"}
                                    id={"newsletter-btn"}
                                    style={{
                                        background: theme.colors.primary[0],
                                        color: "#fff",
                                    }}
                                />
                            </Form>
                        )}
                    </Formik>
                </Container>
            </section>
            {opened && (
                <Modal
                    size={480}
                    opened={opened}
                    onClose={() => setOpened(false)}
                >
                    <Flex
                        justify={"Center"}
                        align={"center"}
                        direction={"column"}
                        pb={50}
                        pr={50}
                        pl={50}
                    >
                        <img
                            src="/assets/Modal/modal_tick.png"
                            alt=""
                            style={{
                                width: "100%",
                                maxWidth: 126,
                                height: "100%",
                                maxHeight: 126,
                                objectFit: "cover",
                                marginBottom: 24,
                            }}
                        />
                        <p style={{ textAlign: "center" }}>
                            Message Successfully Sent.
                        </p>
                    </Flex>
                </Modal>
            )}
        </>
    );
};
export default ContactUs;
