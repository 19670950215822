import { Text, useMantineTheme } from "@mantine/core";
import { ErrorMessage, Field } from "formik";
import type { InputHTMLAttributes } from "react";
import PhoneInputWithCountrySelect from "react-phone-number-input";

import { InputFieldProps } from "../../types/InputFieldProps";
import { checkFormControl, checkFormGroup } from "../../utils/helpers";

const PhoneNumberField = ({
    name,
    error,
    touch,
    placeHolder,
    labelName,
    textMuted,
    className,
    fieldrequired,
    ...restProps
}: InputFieldProps & InputHTMLAttributes<HTMLInputElement>) => {
    const theme = useMantineTheme();
    return (
        <div className={`${checkFormGroup(error)} ${className}`}>
            {labelName && (
                <label htmlFor={name} className="form-label">
                    <Text
                        component="span"
                        sx={{
                            fontSize: 14,
                            fontWeight: 500,
                            color:
                                theme.colorScheme === "dark"
                                    ? theme.colors.dark[0]
                                    : theme.colors.secondary[4],
                        }}
                    >
                        {labelName}
                    </Text>{" "}
                    {fieldrequired && <span className="asterisk">*</span>}
                </label>
            )}
            <Field name={name} className={checkFormControl(error, touch)}>
                {({ form, field }: any) => {
                    const { setFieldValue } = form;

                    return (
                        <PhoneInputWithCountrySelect
                            name={name}
                            style={{ margin: 0 }}
                            {...restProps}
                            {...field}
                            international
                            className={`${checkFormControl(error, touch)}`}
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="AU"
                            id={name}
                            placeholder={placeHolder}
                            onChange={(val) => setFieldValue(name, val)}
                        />
                    );
                }}
            </Field>

            <ErrorMessage
                name={name}
                component="span"
                className="invalid-feedback"
            />
            {textMuted && <small className="text-muted">{textMuted}</small>}
        </div>
    );
};

export default PhoneNumberField;
