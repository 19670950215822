import type { MantineThemeOverride } from "@mantine/core";

export const homaaleColors: MantineThemeOverride = {
    fontFamily: "Manrope",
    fontSizes: { md: "14px" },
    colors: {
        brand: ["#334155", "#64748B", "#17365C"],
        secondary: ["#0074ED", "#64748B"],
        primary: ["#0074EDE5"],

        homaaleSlate: [
            "#f8fafc",
            "#f1f5f9",
            "#e2e8f0",
            "#cbd5e1",
            "#94a3b8",
            "#64748b",
            "#475569",
            "#334155",
            "#1e293b",
            "#0f172a",
        ],
    },
    breakpoints: {
        xxs: "",
        xs: "576",
        sm: "768",
        md: "992",
        lg: "1200",
        xl: "1400",
        xxl: "",
    },
    primaryColor: "brand",
    primaryShade: 4,
    globalStyles: (theme) => ({
        h1: {
            margin: 0,
            fontSize: "32px",
            color:
                theme.colorScheme === "dark"
                    ? theme.colors.gray[0]
                    : theme.colors.homaaleSlate[8],
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                fontSize: "28px",
            },
        },
        h2: {
            margin: 0,
            fontSize: "24px",
            fontWeight: 400,
            [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                fontSize: "26px",
            },
        },
        h3: {
            margin: 0,
            fontSize: "18px",
        },
        h4: {
            margin: "0px 0px 8px 0px",
            fontSize: "16px",
            fontWeight: 500,
            color:
                theme.colorScheme === "dark"
                    ? theme.colors.gray[2]
                    : theme.colors.homaaleSlate[8],
        },
        h5: {
            margin: 0,
            fontSize: "14px",
        },
        h6: {
            margin: 0,
            fontSize: "1px",
        },
        p: {
            margin: 0,
            fontSize: 16,
            fontWeight: 500,
            color: theme.colors.secondary[1],
        },

        a: {
            color: theme.colors.secondary[4],
            textDecoration: "none",
            "&:hover": {
                color:
                    theme.colorScheme === "dark"
                        ? theme.colors[theme.primaryColor][5]
                        : theme.colors[theme.primaryColor][5],
                transition: "all 0.3s ease",
            },
        },
        ".form-check-input": {
            height: 16,
            width: 16,
            marginRight: 6,
        },
        ".asterisk": {
            color: theme.colors.red[4],
        },
        ".invalid-feedback": {
            fontSize: 12,
            fontWeight: 400,
            display: "block",
            color: theme.colors.red[4],
        },

        ".form-control": {
            background: "#fff",
            border:
                theme.colorScheme === "dark"
                    ? `1px solid ${theme.colors.dark[4]}`
                    : `1px solid ${theme.colors.gray[4]}`,
            boxShadow: "none",
            minHeight: "42px",
            fontSize: 16,
            padding: "0 18px",
            position: "relative",
            borderRadius: 8,
            marginTop: 6,
        },
        ".mantine-Modal-header": {
            position: "relative",
        },
        ".form-group": {
            marginBottom: 24,
            ".is-invalid": {
                border: `1px solid ${theme.colors.red[4]}`,
            },
            ":has(.is-invalid)": {
                ".PhoneInputInput": {
                    color: theme.colors.red[4],
                },
            },
            ".PhoneInput": {
                display: "flex",
                alignItems: "center",
                background:
                    theme.colorScheme === "dark"
                        ? theme.colors.darkBackground[0]
                        : "inherit",

                ".PhoneInputCountry": {
                    height: 35,
                    marginBlock: "auto",
                    marginRight: 10,
                    position: "relative",
                    alignSelf: "stretch",
                    display: "flex",
                    alignItems: "center",

                    ".PhoneInputCountrySelect": {
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        width: "100%",
                        zIndex: 1,
                        border: 0,
                        opacity: 0,
                        cursor: "pointer",
                        background: "#fff",
                    },
                },

                ".PhoneInputInput": {
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: "0%",
                    border: "none",
                    outline: "none",
                    height: "100%",
                    width: "100%",
                    minWidth: 0,
                    background: "transparent",
                },
            },
        },
    }),
    components: {
        Flex: {
            defaultProps: {
                justify: "space-between",
                align: "center",
            },
        },
        Grid: {
            defaultProps: {
                gutter: 30,
            },
        },
    },
};
