import { Flex } from "@mantine/core";
import { useCareerWorkStyles } from "../styles/components/CareerWorkStyle";
import { IconCheck } from "tabler-icons";
export type CarrerProps = {
    title: string;
    desc: string;
};
const CareerWork = ({ title, desc }: CarrerProps) => {
    const { classes } = useCareerWorkStyles();
    return (
        <Flex gap={16} align={"start"} justify={"start"} w={290}>
            <div className={classes.icon_wrapper}>
                <IconCheck
                    color="#0074ED"
                    width={20}
                    height={20}
                    stroke={2.3}
                />
            </div>
            <div className={classes.title}>
                <h1>{title}</h1>
                <p>{desc}</p>
            </div>
        </Flex>
    );
};
export default CareerWork;
