import { Container, Flex, useMantineTheme } from "@mantine/core";
import { useLocation } from "react-router-dom";
import { useCareerDetailStyles } from "../../styles/pages/CareerDetailStyles";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { IconArrowNarrowRight } from "tabler-icons";

const CareerDetail = () => {
    const theme = useMantineTheme();
    const { classes } = useCareerDetailStyles();
    const { state } = useLocation();
    const navigate = useNavigate();
    const handleApplyClick = () => {
        navigate(`/form?id=${state.id}`, {
            state: {
                department: state.department,
                interested_position: state.interested_position,
            },
        });
    };
    return (
        <Container size={1397} mt={60}>
            <div className={classes.root}>
                <Flex justify={"center"} align={"center"} direction={"column"}>
                    <h2
                        style={{
                            fontSize: 40,
                            fontWeight: 700,
                            color: theme.colors.brand[0],
                            marginTop: 8,
                            textAlign: "center",
                        }}
                    >
                        JOIN OUR{" "}
                        <span
                            style={{
                                fontSize: 40,
                                textAlign: "center",
                                fontWeight: 400,
                                color: theme.colors.primary[0],
                            }}
                        >
                            TEAM
                        </span>
                    </h2>
                    <p
                        style={{
                            fontSize: 16,
                            textAlign: "center",
                            fontWeight: 400,
                            color: theme.colors.brand[1],
                            marginBottom: 100,
                        }}
                    >
                        Where we believe in growth, ascension and momentum
                    </p>
                </Flex>
                {parse(state.description)}
                {/* <FormButton
          mb={78}
          mt={100}
          maxwidth="192px"
          size={"md"}
          handleClick={handleApplyClick}
          name={"Apply"}
          style={{
            background: theme.colors.primary[0],
            color: "#fff",
          }}
          id={""}
        /> */}
                <Flex
                    mb={78}
                    gap={10}
                    mt={100}
                    justify={"center"}
                    align={"center"}
                    onClick={handleApplyClick}
                    sx={{
                        background: "#0074ED",
                        width: 192,
                        padding: "10px 100px 10px 100px",
                        borderRadius: 4,
                        cursor: "pointer",
                    }}
                >
                    <p
                        style={{
                            color: "white",
                            fontSize: 16,
                            fontWeight: 500,
                        }}
                    >
                        Apply
                    </p>
                    <IconArrowNarrowRight size={24} color="white" />
                </Flex>
            </div>
        </Container>
    );
};
export default CareerDetail;
